import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoleService } from '../../service/user-roles/role.service';
import { finalize } from 'rxjs/operators';
import { StorageService } from 'src/app/core/service/storage.service';
import { UsersService } from '../../service/user-service/users.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../service/auth/auth.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public systemSettingCollapsed = false;
  public adminCollapsed = false;
  public operationCollapsed = false;
  public humanResourceCollapsed = false;
  public communicationCollapsed = false;
  public centralBuilderCollapsed = false;
  public gamificationCollapsed = false;

  constructor(
    private roleService: RoleService,
    private storageService: StorageService,
    private userService: UsersService,
    public toastService: ToastrService,
    public authservice: AuthService,
    private location: Location,
    private router: Router,
  ) {}
  @Input() activeUrl: string;
  @Input() isToggle: boolean;
  rolesArray: any;
  @Output('toggleSideNav') toggleSideNav: EventEmitter<any> =
    new EventEmitter();
    isSideNavOpen: boolean = false; 
  ngOnInit(): void {
    this.loadUserPrivilege();
  }
  toggleMenu(val="") {
    if (val){
      this.location.back();
      setTimeout(() => {
        this.router.navigateByUrl(val);
      }, 100);

    }
    // Optionally trigger the toggle again
    setTimeout(() => {
      this.isSideNavOpen = !this.isSideNavOpen; // Toggle the state back
      this.toggleSideNav.emit(this.isSideNavOpen); // Emit the new state (open)
    }, 1000);
    this.userService.getPrivilege().subscribe(
      (res) => {
        localStorage.setItem('privilegeFlags', JSON.stringify(res.privileges));
        this.loadUserPrivilege();
      },
      (error) => {
        if (error.status === 402) {
          this.toastService.error(error.error);
          localStorage.removeItem('privilegeFlags');
          this.authservice.logout();
        }
      }
    );
  }

  loadUserPrivilege() {
    const data = localStorage.getItem('privilegeFlags');
    this.rolesArray = JSON.parse(data);
    /*const subscribe = this.roleService
      .getUserPrivilege()
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe((response: any) => {
        this.rolesArray = response.data.privilegeFlags;
      });*/
  }

  hasPrivileges(...privileges: string[]): boolean {
    return privileges.some((privilege) => this.rolesArray?.[privilege]);
  }
}
