import { COMMON_MESSAGES, getAssignedUsers } from 'src/app/core/utils/helper';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import { debounceTime, finalize, filter } from 'rxjs/operators';
import { CompanyService } from 'src/app/admin/service/companyservice/company.service';

import { UploadAdapter } from 'src/app/shared/ckEditor-ImageUpload/ckeditor-imageUpload';
import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import { CentralBuilderService } from 'src/app/users/service/central-builder/central-builder.service';
import { ChannelService } from 'src/app/users/service/channel/channel.service';
import { CustomFieldService } from 'src/app/users/service/custom-field/custom-field.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { environment } from 'src/environments/environment';
// import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { faAngry, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['./question-modal.component.scss'],
})
export class QuestionModalComponent implements OnInit {
  createQuestionForm: FormGroup;
  photopickForm: FormGroup;
  planBuUnitDropDownLoading: boolean = false;
  apppoinmentDropDownLoading: boolean = false;
  CustomFieldDropDownLoading: boolean = false;
  isPopupOpen: boolean = false;
  addedCustomfield;
  buDropDownToClear = null;
  apoinemntDropDownToClear = null;
  customFeildDropDownToClear = null;
  adminDropDownToClear = null;
  moreThanOneOption: boolean = false;
  isExtendOptions: boolean = false;
  authorDropDownToClear = null;
  userDetailsEditIndex: number;
  freeTextRange: number;
  isUserDetailsEdited: boolean = false;
  profileFields = [];
  planBuUnitDropDown: any[];
  appoinmentDropDown: any[];
  fieldNeedsToUpdate: any[];
  order = [];
  @Input() value;
  @Input() isEdit;
  successMessage = '';
  submittedForm: boolean = false;
  private _success = new Subject<string>();
  conditionalQuestions = [];
  lookupData = [];
  lookupFields = null;
  conditionalAnswers = [];
  attachMents = [
    {
      value: 'png',
      selected: false,
    },
    {
      value: 'jpg',
      selected: false,
    },
    {
      value: 'jpeg',
      selected: false,
    },
    {
      value: 'pdf',
      selected: false,
    },
    {
      value: 'svg',
      selected: false,
    },
    {
      value: 'doc',
      selected: false,
    },
    {
      value: 'xls',
      selected: false,
    },
    {
      value: 'csv',
      selected: false,
    },
    {
      value: 'ppt',
      selected: false,
    },
  ];
  defaultProfileFields = [
    {
      name: 'User Name',
      isSelected: false,
    },
    {
      name: 'Staff Id',
      isSelected: false,

    },
    {
      name: 'Business Unit',
      isSelected: false,

    },
    {
      name: 'Appointment',
      isSelected: false,

    },
    {
      name: 'Email',
      isSelected: false,

    },
    {
      name: 'Mobile',
      isSelected: false,

    },
    {
      name: 'Given Name',
      isSelected: false,

    },
    {
      name: 'Office Phone',
      isSelected: false,

    },
    {
      name: 'Surname',
      isSelected: false,

    },
    {
      name: 'Reporting Officer',
      isSelected: false,

    },
    {
      name: 'Office',
      isSelected: false,

    },
    {
      name: 'Employment Status',
      isSelected: false,

    },
  ]
  questionTypes = [
    {
      name: 'Multiple Choices',
      type: 2,
    },
    {
      name: 'Check Box',
      type: 5,
    },
    {
      name: 'Polling',
      type: 4,
    },
    {
      name: 'True or False Options',
      type: 3,
    },
    {
      name: 'Signature',
      type: 6,
    },
    {
      name: 'Profile Fields',
      type: 7,
    },
    {
      name: 'Free Text(Long)',
      type: 1,
    },
    {
      name: 'Free Text(Short)',
      type: 8,
    },
    {
      name: 'Numeric',
      type: 9,
    },
    {
      name: 'Date & Time',
      type: 10,
    },
    {
      name: 'Drop Down',
      type: 11,
    },
    {
      name: 'Attachment',
      type: 12,
    },
    {
      name: 'Star Rating',
      type: 13,
    },
    {
      name: 'Nominate User',
      type: 14,
    },
    {
      name: 'Sequence Type',
      type: 15,
    },
    {
      name: 'Photopick',
      type: 16,
    },
    {
      name: 'Auto Populate',
      type: 21,
    },
    {
      name: 'Button Action',
      type: 22,
    },
  ];
  noOfStars = [5, 6, 7, 8, 9, 10];
  serviceCall = ['SERVICE API'];

  buttonsValues = ["currrentDate", "currentTime", "currentDateTime"];
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;
  @ViewChild('myImage', { static: false }) myImage: ElementRef;

  public options: Object = {
    key: environment.froalaEditorKey,
    videoResponsive: true,
    quickInsertEnabled: false,

    videoInsertButtons: [
      'videoBack',
      '|',
      'videoByURL',
      'videoEmbed',
      'videoUpload',
    ],
    videoEditButtons: [
      'videoReplace',
      'videoRemove'
    ],
    imageEditButtons: ['imageReplace', 'imageAlign', 'imageCaption', 'imageRemove'],
    listAdvancedTypes: true,
    toolbarButtons: [
      'undo',
      'redo',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'fontFamily',
      'fontSize',
      'color',
      'formatBlock',
      'blockStyle',
      'inlineStyle',
      'align',
      'insertOrderedList',
      'insertUnorderedList',
      'outdent',
      'indent',
      'selectAll',
      'createLink',
      'insertImage',
      'insertVideo',
      'table',
      'undo',
      'redo',
      'html',
      'save',
      'insertHorizontalRule',
      'uploadFile',
      'removeFormat',
      'fullscreen',
    ],
    videoUploadURL: `${environment.baseUrl}/notification/uploadContentFiles/`,
    requestHeaders: {
      Authorization: localStorage.getItem('flexiusersToken'),
    },
    // Set the image upload URL.
    imageUploadURL: `${environment.baseUrl}/notification/uploadContentFiles/`,

    // Set request type.
    imageUploadMethod: 'POST',
    attribution: false,
    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    // Set request type.
    videoUploadMethod: 'POST',

    // Set max video size to 50MB.
    videoMaxSize: 50 * 1024 * 1024,

    // Allow to upload MP4, WEBM and OGG
    videoAllowedTypes: ['webm', 'jpg', 'ogg', 'mp4', 'mov', 'MKV'],
    events: {
      'video.beforeUpload': function (videos) {
        // Return false if you want to stop the video upload.
      },
      'video.uploaded': function (response) {
        // Video was uploaded to the server.
      },
      'video.inserted': function ($img, response) {
        // Video was inserted in the editor.
      },
      'video.replaced': function ($img, response) {
        // Video was replaced in the editor.
      },
      'video.error': function (error, response) { },
      'image.beforeUpload': function (images) {
        // Return false if you want to stop the image upload.
      },
      'image.uploaded': function (response) {
        // Image was uploaded to the server.
      },
      'image.inserted': function ($img, response) {
        // Image was inserted in the editor.
      },
      'image.replaced': function ($img, response) {
        // Image was replaced in the editor.
      },
    },
  };
  userDetailsForm: FormGroup;
  conditionalQuestionForm: FormGroup;

  userDropDown = [];
  userDetails = [];
  isLoading: boolean = false;
  bussinesUnitplanPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
    filter: { type: string };
  };
  appoinmentPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };
  CustomFieldPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };
  userPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };
  dateTimeChecked: {
    date: number;
    time: number;
  };
  adminPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };
  adminDropDown: any[];
  adminLoading: boolean;
  authorLoading: boolean;
  UpdateduserDetails: any[] = [];
  questionTypePolling: number = 4;

  icons: string[] = [
    'fa-angry',
    'fa-address-book',
    'fa-address-card',
    'fa-adjust',
    'fa-home',
    'fa-search',
    'fa-user',
    'fa-facebook',
    'fa-check',
    'fa-download',
    'fa-twitter',
    'fa-image',
    'fa-instagram',
    'fa-phone',
    'fa-bars',
    'fa-envelope',
    'fa-linkedin',
    'fa-star',
    // Add more Font Awesome icon classes as needed
  ];
  showIconPicker: boolean = false;
  selectedIcon: string;

  constructor(
    public activeModal: NgbActiveModal,
    public http: HttpClient,
    private formbuilder: FormBuilder,
    private companyService: CompanyService,
    private toastService: ToastrService,
    private centralBuilderService: CentralBuilderService,
    private customFields: CustomFieldService,
    private modalService: NgbModal,

    private userService: UsersService,

    private buUnit: BussinessUnitService,
    private customFieldService: CustomFieldService,
    private channelService: ChannelService,
  ) {
    this.bussinesUnitplanPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 8,

      search: '',
      filter: {
        type: 'plan',
      },
    };
    this.appoinmentPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',
    };
    this.CustomFieldPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',
    };
    this.userPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',
    };
    this.adminPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',
    };
    this.dateTimeChecked = {
      date: 0,
      time: 0,
    }
  }

  ngOnInit(): void {
    const solidIcons = Object.keys(far);
    this.icons = [...solidIcons].map((name)=> this.toKebabCase(name))
    this.icons = ['fa-calendar-alt',
      'fa-home',
      'fa-map-pin',
      'fa-location-arrow',
      'fa-clock', ...this.icons]
    // console.log(' this.icons ',  this.icons )
    for (let i = 0; i < this.value.questionslength; i++) {
      this.order.push(i + 1);
    }
    this.order.push(this.order.length + 1);
    this.initForm();
    this.buildPhotopickForm()
    this.initConditionalQuestionFrom();
    if (this.value.type === 3) {
      const Control = this.OptionControl;
      for (let i = 0; i < 2; i++) {
        if (i == 0) {
          Control.push(
            this.formbuilder.group({
              value: ['true', Validators.required],
              correctAns: false,
              imageSrc: '',
            })
          );
        } else {
          Control.push(
            this.formbuilder.group({
              value: ['false', Validators.required],
              correctAns: false,
              imageSrc: '',
            })
          );
        }
      }
    }
    if (this.value.type === this.questionTypePolling) {
      const Control = this.OptionControl;
      Control.push(
        this.formbuilder.group({
          value: ['', Validators.required],
          correctAns: false,
          imageSrc: '',
          pollingDescription: '',
          pollingDescriptionImageSrc: ''
        })
      );
    }
    if ([7].includes(this.value.type)) {
      this.f.question.clearValidators();
      this.f.maxlength.updateValueAndValidity();
    }
    if ([1, 8].includes(this.value.type)) {
      this.f.maxlength.setValidators([Validators.required]);
      this.f.maxlength.updateValueAndValidity();
    }
    if (this.value.type == 14) {
      this.inituserDetailsFrom();
      // this.loadBussinessPlanUnit();
      // this.loadAppoinment();
      // this.loadCustomField();
    }

    if (this.value.type == 7) {
      const { data, total, ...rest } = this.CustomFieldPTable;

      const sub = this.customFields
        .getCustomField(rest)
        .pipe(
          finalize(() => {
            sub.unsubscribe();
          })
        )
        .subscribe(
          (response: any) => {
            const { count, data } = response.data;
            const modifiedData = data.map((rawProduct) => {
              return { ...rawProduct, isSelected: false };
            });
            this.CustomFieldPTable.data = modifiedData;
            this.value.profile?.forEach((profile) => {
              this.CustomFieldPTable.data.forEach((tableData) => {
                if (profile.questionName === tableData.fieldName) {
                  tableData.isSelected = true;
                }
              });
            });
          },
          (error) => {
            this.CustomFieldPTable.data = [];
            this._success.next('No list found');
          }
        );
    }
    if ([2, 5, 15].includes(this.value.type)) {
      this.OptionControl.push(this.createOption());
    }

    if ([11].includes(this.value.type)) {
      this.createQuestionForm.controls.isDynamicData.setValue(this.value.isDynamicData)
      if (!!this.value.isDynamicData) {
        this.createQuestionForm.controls.serviceApi.setValue("SERVICE API")
      }
      if (!this.value.isDynamicData) {
        this.OptionControl.push(this.createOption());
      }
    }

    if ([10].includes(this.value.type)) {
      this.f.date.setValidators([Validators.required]);
      this.f.time.setValidators([Validators.required]);
      this.f.date.updateValueAndValidity();
      this.f.time.updateValueAndValidity();
    }
    if ([13].includes(this.value.type)) {
      this.f.value.setValue(null);
      this.f.value.setValidators([Validators.required]);
      this.f.value.updateValueAndValidity();
    }
    if ([22].includes(this.value.type)) {
      this.f.buttonValue.setValidators([Validators.required]);
      this.f.buttonValue.updateValueAndValidity();
    }

    if ([21].includes(this.value.type)) {
      this.f.sourceQuestion.setValidators([Validators.required]);
      this.f.sourceQuestion.updateValueAndValidity();
      this.f.lookupTable.setValidators([Validators.required]);
      this.f.lookupTable.updateValueAndValidity();
      this.f.lookupField.setValidators([Validators.required]);
      this.f.lookupField.updateValueAndValidity();
      this.f.displayField.setValidators([Validators.required]);
      this.f.displayField.updateValueAndValidity();
    }
    if (!!this.value.isEdited) {
      const Control = this.conditionalQuestionsControl;
      this.getConditionalQuestions()
      if (this.value.conditionalQuestions.length > 0) {
        this.createQuestionForm.controls.isConditional.setValue(true)
      } else {
        this.createQuestionForm.controls.isConditional.setValue(false)
      }
      this.value.conditionalQuestions.length &&
        this.value.conditionalQuestions.forEach((element) => {
          let field = this.formbuilder.group({
            questionId: [
              element.questionId?._id,
              Validators.compose([Validators.required]),
            ],
            question: [
              element.questionId?.name,
              Validators.compose([Validators.required]),
            ],
            optionId: [
              element.optionId?._id,
              Validators.compose([Validators.required]),
            ],
            option: [
              element.optionId?.name,
              Validators.compose([Validators.required]),
            ],
          });
          Control.push(field);
        });
      this.dateTimeChecked = {
        date: this.value.dateTime?.includes(1) ? 1 : 0,
        time: this.value.dateTime?.includes(2) ? 2 : 0
      }
      this.createQuestionForm.patchValue({
        moduleId: this.value.moduleId,
        question: this.value.question,
        type: this.value.type,
        indexNum: +this.value.indexNum + 1,
        required: true,
        value: this.value.value,
        date: 1,
        time: 2,
        maxlength: this.value.maxlength,
        minlength: !!this.value.isNumeric ? this.value.maxlength : 1,
        sourceQuestion: this.value.sourceQuestion,
        lookupTable:this.value.lookupTable,
        lookupField:this.value.lookupField,
        displayField:this.value.displayField,
        buttonValue: this.value.buttonValue,
        sectionId: this.value.sectionId,
        optionsView: this.value.optionsView,
        editable: this.value.explanation ? true : false,
        explanation: this.value.explanation,
        imageSrc: this.value.imgeSrc,
        questionIcon: this.value.questionIcon,
        extendedOption: this.value.type === this.questionTypePolling ? this.value.extendedOption:false,
        isNumeric:  this.value.isNumeric,
      });
      this.selectedIcon = this.value.questionIcon,
      this.isExtendOptions = this.value.type === this.questionTypePolling ? this.value.extendedOption:false;
      const formDateArray = this.dateTimeQuestionControl;
      formDateArray.clear();
      if (this.value?.dateTime?.length) {
        this.value.dateTime.forEach((control) => {
          let field = this.formbuilder.group({
            date: [parseInt(control)],
          });
          formDateArray.push(field);
        }
        );
      }
      if (this.value.options.length != 0) {
        if (this.value.type === 12) {
          this.clearFormArray(this.ProfileControl);
          this.value.options.forEach((el) => {
            let field = this.formbuilder.group({
              value: [el.value, Validators.required],
              selected: el.selected,
            });
            this.OptionControl.push(field);
          });
          this.value.options.forEach((el) => {
            this.attachMents.map((element) => {
              if (el.value === element.value) {
                element.selected = true;
              }
            });
          });
        }
        else {
          // alert(JSON.stringify(this.value.options))
          this.clearFormArray(this.OptionControl);
          this.value.options.forEach((el) => {
            let field = this.formbuilder.group({
              value: [el.value, Validators.required],
              correctAns: el.correctAns,
              imageSrc: el.imageSrc,
              ...(this.value.type === this.questionTypePolling) ? {pollingDescription: (el.pollingDescription ? el.pollingDescription : '')} : {},
              ...(this.value.type === this.questionTypePolling) ? {pollingDescriptionImageSrc: (el.pollingDescriptionImageSrc ? el.pollingDescriptionImageSrc : '')} : {},
            });
            this.OptionControl.push(field);
          });
        }
      }
      if (this.value.profile.length != 0) {
        this.value.profile.forEach((el) => {
          let field = this.formbuilder.group({
            questionName: [
              el.questionName,
              Validators.compose([Validators.required]),
            ],
          });
          this.ProfileControl.push(field);
          this.defaultProfileFields.map((element) => {
            if (el.questionName === element.name) {
              element.isSelected = true;
            }
          });
        });
      }
      if (this.value && this.value.assignUsers && this.value.assignUsers.length && this.value.assignUsers.length != 0) {
        this.userDetails = this.value.assignUsers;
        this.UpdateduserDetails = getAssignedUsers(this.value.assignUsers);
      }

      if (this.value.type === 16) {
        this.photopickForm.controls.imgSrc.setValue(this.value.imageSrc);
        const coordinates = this.value.options
        const Control = this.imgDescControls;
        coordinates?.forEach(element => {
          let field = this.formbuilder.group({
            value: [element.value, Validators.compose([Validators.required])],
            x: [Number(element?.coordinates?.x), Validators.compose([Validators.required])],
            y: [Number(element?.coordinates?.y), Validators.compose([Validators.required])],
            radious: [Number(element?.coordinates?.radious), Validators.compose([Validators.required])],
            imgWidth: [Number(element?.coordinates?.imgWidth), Validators.compose([Validators.required])],
            imgHeight: [Number(element?.coordinates?.imgHeight), Validators.compose([Validators.required])],
            id: [element?._id, Validators.compose([Validators.required])],
          });
          Control.push(field);
        });
      }
    }

    this._success.subscribe((message) => (this.successMessage = message));
    this._success.pipe(debounceTime(1000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  toKebabCase(iconName: string): string {
    return iconName.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }

  getMoreThanOne() {
    this.moreThanOneOption = !this.moreThanOneOption
  }

  extendOptions() {
    this.isExtendOptions = !this.isExtendOptions
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };
  get f() {
    return this.createQuestionForm.controls;
  }
  get OptionControl(): FormArray {
    return this.createQuestionForm?.get('options') as FormArray;
  }

  get imgDescControls(): FormArray {
    return this.photopickForm?.get('imgDescription') as FormArray;
  }
  get ProfileControl(): FormArray {
    return this.createQuestionForm?.get('profile') as FormArray;
  }
  get conditionalQuestionsControl(): FormArray {
    return this.createQuestionForm?.get('conditionalQuestions') as FormArray;
  }
  get dateTimeQuestionControl(): FormArray {
    return this.createQuestionForm?.get('dateTime') as FormArray;
  }

  inituserDetailsFrom() {
    return (this.userDetailsForm = this.formbuilder.group({
      businessUnits: this.formbuilder.array([], [Validators.required]),
      buFilterType: [1, Validators.required],
      appointments: this.formbuilder.array([]),
      subSkillSets: this.formbuilder.array([]),
      user: this.formbuilder.array([]),
      allBuToken: '',
      allBuTokenStaffId: '',
      customField: this.formbuilder.array([]),
    }));
  }
  initConditionalQuestionFrom() {
    return (this.conditionalQuestionForm = this.formbuilder.group({
      questionId: ['', Validators.required],
      optionId: ['', Validators.required],
    }));
  }
  initForm() {
    this.createQuestionForm = this.formbuilder.group({
      moduleId: this.value.moduleId,
      question: ['', Validators.required],
      type: this.value.type,
      indexNum: [this.order.length, Validators.required],
      required: false,
      sectionId: this.value.sectionId,
      // userDetails: this.formbuilder.array([]),
      value: 0,
      options: this.formbuilder.array([]),
      dateTime: this.formbuilder.array([]),
      date: 1,
      time: 2,
      maxlength: '',
      minlength: '',
      profile: this.formbuilder.array([]),
      conditionalQuestions: this.formbuilder.array([]),
      optionsView: 0,
      editable: false,
      isConditional: false,
      explanation: '',
      imageSrc: '',
      extendOptions:false,
      sourceQuestion: "",
      lookupTable: "",
      lookupField: "",
      displayField: "",
      isDynamicData: false,
      serviceApi: "",
      buttonValue: "",
      questionIcon: "",
      isNumeric: false
    });
    if (this.value.type == 21) {
      this.getConditionalQuestions();
      this.getlookupData();
    }
  }

  updatedUserDetails(userDetails) {
    this.UpdateduserDetails = userDetails;
  }


  getConditionalQuestions() {
    this.conditionalQuestions = [];
    const sub = this.centralBuilderService
      .getSingleQuestions(this.value.moduleId)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res) => {
        const newData = res?.data?.data?.sections.flatMap((section) =>
          section.questions.map(({ _id, question, options, type }) => ({
            _id,
            question,
            options,
            type,
          }))
        );
        this.conditionalQuestions = newData
      });
  }

  getlookupData() {
    this.lookupData = [];
    const sub = this.centralBuilderService
      .readLookupTable()
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.lookupData = res?.data;
      if (this.value.type == 21)  this.getLookupFields(this.value.lookupTable);

      });
  }

  getContionalAnswers(value) {
    this.conditionalQuestions.forEach((ele) => {
      if (ele._id === value._id) {
        this.conditionalAnswers = ele.options;
        return;
      }
    });
  }

  getContionalAnswersforSourceQuestions(value) {
    this.conditionalQuestions.forEach((ele) => {
      if (ele._id === value) {
        this.conditionalAnswers = ele.options;
        return;
      }
    });
  }

  getLookupFields(value) {
    console.log(value);
   let filteredData = this.lookupData.filter((val) => val?._id === value);
    this.lookupFields = filteredData[0]?.columns;
  }

  async addConditionalQuestion({ questionId, optionId }) {
    const Control = this.conditionalQuestionsControl;

    let field = this.formbuilder.group({
      questionId: [questionId._id, Validators.compose([Validators.required])],
      question: [
        questionId.question,
        Validators.compose([Validators.required]),
      ],
      optionId: [optionId._id, Validators.compose([Validators.required])],
      option: [optionId.value, Validators.compose([Validators.required])],
    });
    Control.push(field);
    this.initConditionalQuestionFrom();
  }
  onClearConditionalQuestion() {
    this.initConditionalQuestionFrom();
  }
  conditionQuestionDelete(index) {
    const Control = this.conditionalQuestionsControl;

    Control.removeAt(index);
  }

  async changeQuickLinkStatus(index, value) {
    if (value) {
      const Control = this.ProfileControl;
      const index = await Control.value.findIndex(
        (x) => x.questionName === value
      );
      if (index > -1) {
        Control.removeAt(index);
      } else {
        let field = this.formbuilder.group({
          questionName: [value, Validators.compose([Validators.required])],
          selected: true,
        });
        Control.push(field);
      }
    }
  }

  async setDateTime(value) {

    if (value) {
      const Control = this.dateTimeQuestionControl;
      const index = await Control.value.findIndex(
        (x) => +x.date === +value
      );
      if (index > -1) {
        Control.removeAt(index);
      } else {
        let field = this.formbuilder.group({
          date: [parseInt(value)],
        });
        Control.push(field);
      }
    }
  }

  cancelPopup() {
    this.activeModal.dismiss();
  }

  createOption(): FormGroup {
    return this.formbuilder.group({
      value: ['', Validators.required],
      correctAns: false,
      imageSrc: '',
      ...(this.value.type ===4) ? {pollingDescription: ''} : {},
      ...(this.value.type ===4) ? {pollingDescriptionImageSrc: ''} : {}
    });
  }

  async onCreateQuestion() {
    this.submittedForm = true;
    let typesforExclude = [21];
    // if ((this.value?.type == "22") || (this.value?.type == "21")) {
    //   this.createQuestionForm.patchValue({
    //     type : this.value?.type == 22 ? 22 : 21
    //   });
    //   this.createQuestionForm.value.options = [];
    // }
    if (!typesforExclude.includes(this.value?.type)) {
      delete this.createQuestionForm.value.sourceQuestion;
      // delete this.createQuestionForm.value.isDynamicData;
      delete this.createQuestionForm.value.lookupTable;
      delete this.createQuestionForm.value.lookupField;
      delete this.createQuestionForm.value.displayField;
    }
    if (![11].includes(this.value?.type)) {
      delete this.createQuestionForm.value.isDynamicData;
      delete this.createQuestionForm.value.serviceApi;
    }

    if ((this.value.type == 11) && !!this.createQuestionForm.value.isDynamicData) {
      delete this.createQuestionForm.value.options
    }

    if (!this.createQuestionForm.valid) {
      this._success.next(`Please fill all fields`);
      return;
    }

    if ((this.value.type == 4) || (this.value.type == 5) || (this.value.type == 2)) {
      if (this.createQuestionForm.value.options.length < 2) {
        this.toastService.error(`Select atleast two options`, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        return;
      }
    }

    if (this.value.type === 12) {
      if (this.createQuestionForm.value.options.length === 0) {
        this.toastService.error(`Please Select the Checkboxes`, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        return;
      }
    }

    if (this.value.type === 10) {
      if (this.createQuestionForm.value.dateTime.length === 0) {
        this.toastService.error(`Please Select the Checkboxes`, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        return;
      }
    }
    if (this.value.type === 7) {
      if (this.createQuestionForm.value.profile.length === 0) {
        this.toastService.error(`Please select atleast 1 profile field.`, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        return;
      }
    }

    if (this.value.type === 22) {
      if (this.createQuestionForm.value.buttonValue.length === 0) {
        this.toastService.error(`Please select atleast 1 display field.`, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        return;
      }
    }

    if (this.value.type === 14) {
      if (this.UpdateduserDetails.length === 0) {
        this.toastService.error(`Please select and add at least one business unit`, '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          }
        );
        this.isLoading = false;
        return;
      }
    }

    if (this.value.type === 21) {
      this.createQuestionForm.value.sourceQuestion = this.createQuestionForm.value.sourceQuestion;
      this.createQuestionForm.value.lookupTable = this.createQuestionForm.value.lookupTable;
      // if (this.createQuestionForm.value.buttonValue.length === 0) {
      //   this.toastService.error(`Please select atleast 1 display field.`, '', {
      //     timeOut: 3000,
      //     positionClass: 'toast-top-right',
      //   });
      //   return;
      // }
    }

    if (this.value.type === 1 || this.value.type === 8 || this.value.type === 9) {
      this.createQuestionForm.value.optionsView = "1"
    }

    if (this.value.type !== 8) {
      delete this.createQuestionForm.value.isNumeric;
    }

    if (this.value.type === 8) {
      this.createQuestionForm.value.minlength = !!this.value.isNumeric ? this.createQuestionForm.value.maxlength : 1;
    }

    this.isLoading = true;
    const profileData = this.ProfileControl.value.map(({ questionName }) => ({
      questionName,
    }));
    const initialValue = this.dateTimeQuestionControl.value.map(({ date }) => ({
      date,
    }));
    const dateTimeData = initialValue.map((val) => {
      return val.date;
    });
    if (!!this.value.isEdited) {
      const { editable, isConditional, question, ...rest } =
        this.createQuestionForm.value;
      if (this.value.type === 14) {
        const data = {
          ...rest,
          _id: this.value._id,
          sectionId: this.value.sectionId,
          isConditional: isConditional === true ? true : false,
          question: this.value.type == 7 ? 'Profile Details' : question,
          assignUsers: this.UpdateduserDetails,
          dateTime: this.dateTimeQuestionControl.length != 0 ? dateTimeData : [1, 1],
          conditionalQuestions: this.conditionalQuestionsControl.value.map(
            ({ questionId, optionId }) => ({
              questionId,
              optionId,
            })
          ),
        };
        data.indexNum = data.indexNum - 1;
        this.centralBuilderService.createQuestion(data).subscribe(
          (res) => {
            this.cancelPopup();
            this.initForm();
            this.isLoading = false;
            this.toastService.success("Question Created Successfully");
            this.submittedForm = false;
          },
          (error: HttpErrorResponse) => {
            this._success.next(error.error.message);
            this.isLoading = false;
            this.submittedForm = false;
          }
        );
      } else if (this.value.type === 16) {
        if (!this.photopickForm.valid) {
          this._success.next(`Please fill all fields`);
          return;
        }
        const imageCordinatorArray = [];
        this.imgDescControls.value.forEach((ele) => {
          const imgObj = {
            coordinates: {
              x: Number,
              y: Number,
              radious: Number,
              imgHeight: Number,
              imgWidth: Number
            },
            value: String,
            _id: String
          }
          imgObj.coordinates = ele
          imgObj.value = ele.value,
            imgObj._id = ele.id,
            delete imgObj.coordinates['value']
          delete imgObj.coordinates['id']
          !imgObj._id ? delete imgObj._id : ''
          imageCordinatorArray.push(imgObj)
        })

        const data = {
          ...rest,
          _id: this.value._id,
          isConditional: isConditional === true ? true : false,
          question: question,
          sectionId: this.value.sectionId,
          imageSrc: this.photopickForm.value.imgSrc,
          profile: this.ProfileControl.length != 0 ? profileData : [],
          assignUsers: this.UpdateduserDetails,
          conditionalQuestions: this.conditionalQuestionsControl.value.map(
            ({ questionId, optionId }) => ({
              questionId,
              optionId,
            })
          ),
          options: imageCordinatorArray,
          chooseCount: this.value.chooseCount,
          ppimageuploadfrom: this.value.ppimageuploadfrom,
          pollingSelectionCount: this.value.pollingSelectionCount,
          status: this.value.status,
          createdAt: this.value.createdAt,
          updatedAt: this.value.updatedAt,
          __v: this.value.__v,
          optionsView: (this.value.optionsView).toString(),
        };

        data.indexNum = data.indexNum - 1;
        delete data.date;
        delete data.time;
        delete data.value;
        delete data.dateTime;
        this.centralBuilderService.createQuestion(data).subscribe(
          (res) => {
            this.cancelPopup();
            this.initForm();
            this.toastService.success("Question Created Successfully")
            this.isLoading = false;
            this.submittedForm = false;
          },
          (error: HttpErrorResponse) => {
            this._success.next(error.error.message);
            this.isLoading = false;
            this.submittedForm = false;
          }
        );
      } else {
        const data = {
          ...rest,
          _id: this.value._id,
          sectionId: this.value.sectionId,
          isConditional: isConditional === true ? true : false,
          ...(this.value.type === this.questionTypePolling) ? {extendedOption: this.isExtendOptions} : {},
          question: this.value.type == 7 ? 'Profile Details' : question,
          dateTime: this.dateTimeQuestionControl.length != 0 ? dateTimeData : [1, 1],
          profile: this.ProfileControl.length != 0 ? profileData : [],
          conditionalQuestions: this.conditionalQuestionsControl.value.map(
            ({ questionId, optionId }) => ({
              questionId,
              optionId,
            })
          ),
        };
        data.indexNum = data.indexNum - 1;
        this.centralBuilderService.createQuestion(data).subscribe(
          (res) => {
            this.cancelPopup();
            this.initForm();
            this.toastService.success("Question Created Successfully");
            this.submittedForm = false;
            // this.onClearAllFrom();
            this.isLoading = false;
          },
          (error: HttpErrorResponse) => {
            this._success.next(error.error.message);
            this.isLoading = false;
            this.submittedForm = false;
          }
        );
      }
    } else {
      const { editable, isConditional, question, ...rest } =
        this.createQuestionForm.value;

      if (this.value.type === 14) {
        const data = {
          ...rest,
          question: this.value.type == 7 ? 'Profile Details' : question,
          isConditional: isConditional === true ? true : false,
          assignUsers: this.UpdateduserDetails,
          sectionId: this.value.sectionId,
          dateTime: this.dateTimeQuestionControl.length != 0 ? dateTimeData : [1, 1],
          conditionalQuestions: this.conditionalQuestionsControl.value.map(
            ({ questionId, optionId }) => ({
              questionId,
              optionId,
            })
          ),
        };
        this.centralBuilderService.createQuestion(data).subscribe(
          (res) => {
            this.cancelPopup();
            this.initForm();
            this.toastService.success("Question Created Successfully")
            this.isLoading = false;
            this.submittedForm = false;
          },
          (error: HttpErrorResponse) => {
            this._success.next(error.error.message);
            this.isLoading = false;
            this.submittedForm = false;
          }
        );
      } else if (this.value.type === 16) {
        if (!this.photopickForm.valid) {
          this._success.next(`Please fill all fields`);
          return;
        }
        const imageCordinatorArray = [];
        this.imgDescControls.value.forEach((ele) => {
          const imgObj = {
            coordinates: {
              x: Number,
              y: Number,
              radious: Number,
              imgHeight: Number,
              imgWidth: Number
            },
            value: String
          }
          imgObj.coordinates = ele
          imgObj.value = ele.value,
            delete imgObj.coordinates['value']
          imageCordinatorArray.push(imgObj)
        })
        const data = {
          ...rest,
          question: question,
          sectionId: this.value.sectionId,
          imageSrc: this.photopickForm.value.imgSrc,
          conditionalQuestions: this.conditionalQuestionsControl.value.map(
            ({ questionId, optionId }) => ({
              questionId,
              optionId,
            })
          ),
          options: imageCordinatorArray
        };
        delete data.date;
        delete data.dateTime;
        delete data.maxlength;
        delete data.profile;
        delete data.required;
        delete data.time;
        delete data.value;
        delete data.minlength;
        data.explanation === null || data.explanation === '' ? delete data.explanation : ''
        this.centralBuilderService.createQuestion(data).subscribe(
          (res) => {
            this.cancelPopup();
            this.initForm();
            this.buildPhotopickForm();
            this.isLoading = false;
            this.toastService.success("Question Created Successfully");
            this.submittedForm = false;
          },
          (error: HttpErrorResponse) => {
            this._success.next(error.error.message);
            this.isLoading = false;
            this.submittedForm = false;
          }
        );
      } else {
        const data = {
          ...rest,
          isConditional: isConditional === true ? true : false,
          sectionId: this.value.sectionId,
          profile: this.ProfileControl.length != 0 ? profileData : [],
          ...(this.value.type === this.questionTypePolling) ? {extendedOption: this.isExtendOptions} : {},
          dateTime: this.dateTimeQuestionControl.length != 0 ? dateTimeData : [1, 1],
          question: this.value.type == 7 ? 'Profile Details' : question,
          conditionalQuestions: this.conditionalQuestionsControl.value.map(
            ({ questionId, optionId }) => ({
              questionId,
              optionId,
            })
          ),
        };
        this.centralBuilderService.createQuestion(data).subscribe(
          (res) => {
            this.cancelPopup();
            this.initForm();
            // this.value.type === 14 && this.onClearAllFrom();
            this.isLoading = false;
            this.toastService.success("Question Created Successfully");
            this.submittedForm = false;
          },
          (error: HttpErrorResponse) => {
            this._success.next(error.error.message);
            this.isLoading = false;
            this.submittedForm = false;
          }
        );
      }
    }
  }

  async onClickCorrectAnswer(event, ind) {
    const Control = this.OptionControl;
    if (event.target.checked) {
      this.setCorrectAnsValue(Control);
      let obj = {};
      obj['correctAns'] = true;
      Control.at(ind).patchValue(obj);
    } else {
      this.setCorrectAnsValue(Control);
    }
  }

  async setIsDynamic(event) {
    if (event.target.checked) {
        this.createQuestionForm.value.options.length = 0;
        this.createQuestionForm.patchValue({
          options : []
        });
        this.OptionControl.clear();

    }
    else {
      this.OptionControl.push(this.createOption());
    }
  }

  setCorrectAnsValue(Control) {
    for (let i = 0; i < Control.controls.length; i++) {
      let obj = {};
      obj['correctAns'] = false;
      Control.at(i).patchValue(obj);
    }
  }

  addOptions(): void {
    const Control = this.OptionControl;
    if (!Control.valid) {
      this._success.next(`Please enter answser`);
      return;
    }
    Control.push(this.createOption());
  }
  correctAnswerexists() { }

  onDeleteImage(index) {
    const Control = this.OptionControl;
    let obj = {};
    obj['imageSrc'] = '';
    Control.at(index).patchValue(obj);
  }
  onDeletePollingDescriptionImage(index){
    const Control = this.OptionControl;
    let obj = {};
    obj['pollingDescriptionImageSrc'] = '';
    Control.at(index).patchValue(obj);
  }
  onDeleteOptions(index) {
    const Control = this.OptionControl;
    Control.removeAt(index);
  }

  async onAttachments(index, value) {
    if (value) {
      const Control = this.OptionControl;
      const index = await Control.value.findIndex((x) => x.value === value);

      if (index > -1) {
        Control.removeAt(index);
      } else {
        let field = this.formbuilder.group({
          value: [value, Validators.compose([Validators.required])],
          selected: [true, Validators.compose([Validators.required])],
        });
        Control.push(field);
      }
    }
  }
  BannerUpload(event, index, isDescription?: boolean, isIcon?: false) {
    const Control = this.OptionControl;
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const fileUpload = this.companyService
        .uploadAnswerImage(file)
        .pipe(
          finalize(() => {
            fileUpload.unsubscribe();
          })
        )
        .subscribe(
          (data: any) => {
            let obj = {};
            if (isDescription) {
              this.createQuestionForm.value.pollingDescriptionImageSrc = `${environment.imageUrl}/${data.data.filePath}`;
              obj['pollingDescriptionImageSrc'] = `${environment.imageUrl}/${data.data.filePath}`;
            }
            else if (!!isIcon){
              this.createQuestionForm.value.questionIcon = `${environment.imageUrl}/${data.data.filePath}`;
            }
            else {
              this.createQuestionForm.value.imageSrc = `${environment.imageUrl}/${data.data.filePath}`;
              obj['imageSrc'] = `${environment.imageUrl}/${data.data.filePath}`;
            }
            Control.at(index).patchValue(obj);
          },
          (error: HttpErrorResponse) => {
            this._success.next(error.error.message);
          }
        );
      } else {
        this.toastService.error(COMMON_MESSAGES.UPLOAD_ONLY_IMAGE);
        event.target.value = '';
        file = null;
      }
    }

  }

  IconUpload(event) {
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const fileUpload = this.companyService
        .uploadAnswerImage(file)
        .pipe(
          finalize(() => {
            fileUpload.unsubscribe();
          })
        )
        .subscribe(
          (data: any) => {
            this.createQuestionForm.patchValue({
              questionIcon : `${environment.imageUrl}/${data.data.filePath}`
            });
          },
          (error: HttpErrorResponse) => {
            this._success.next(error.error.message);
          }
        );
      } else {
        this.toastService.error(COMMON_MESSAGES.UPLOAD_ONLY_IMAGE);
        event.target.value = '';
        file = null;
      }
    }

  }

  openIconPicker() {
    this.showIconPicker = true;
  }

  closeIconPicker() {
    this.showIconPicker = false;
  }

  selectIcon(icon: string) {
    this.selectedIcon = icon;
    this.createQuestionForm.patchValue({
      questionIcon: this.selectedIcon
    });
    this.closeIconPicker(); // Close the modal after selecting an icon
  }

  onDeleteIcon(index) {
    this.createQuestionForm.patchValue({
      questionIcon : ""
    });
    this.selectedIcon = "";
  }

  buildPhotopickForm() {
    this.photopickForm = this.formbuilder.group({
      imgSrc: ['', Validators.compose([Validators.required])],
      imgDescription: this.formbuilder.array([]),
    })
  }

  photoUpload(event) {
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const fileUpload = this.companyService
          .uploadAnswerImage(file)
          .pipe(
            finalize(() => {
              fileUpload.unsubscribe();
            })
          )
          .subscribe(
            (data: any) => {
              this.photopickForm.controls.imgSrc.setValue(`${environment.imageUrl}/${data.data.filePath}`);
              this.imgDescControls.controls = []
            },
            (error: HttpErrorResponse) => {
              this._success.next(error.error.message);
            }
          );
      } else {
        this.toastService.error(COMMON_MESSAGES.UPLOAD_ONLY_IMAGE);
        event.target.value = '';
        file = null;
        this.imgDescControls.controls = [];
        this.photopickForm.controls.imgSrc.reset()
      }

    }
  }

  onDeleteDescription(index) {
    const Control = this.imgDescControls;
    Control.removeAt(index)
  }

  onImageClick(event: MouseEvent) {
    const imageElement = event.target as HTMLImageElement;
    const imageWidth = imageElement.offsetWidth;
    const imageHeight = imageElement.offsetHeight;
    const offsetX = event.offsetX;
    const offsetY = event.offsetY;
    const percentageX = ((offsetX / imageWidth) * 100) - 3.3;
    const percentageY = ((offsetY / imageHeight) * 100) - 5.8;

    const Control = this.imgDescControls;
    let field = this.formbuilder.group({
      value: ['', Validators.compose([Validators.required])],
      x: [percentageX, Validators.compose([Validators.required])],
      y: [percentageY, Validators.compose([Validators.required])],
      radious: [36, Validators.compose([Validators.required])],
      imgWidth: [imageWidth, Validators.compose([Validators.required])],
      imgHeight: [imageHeight, Validators.compose([Validators.required])],
    });
    Control.push(field);
  }

  openPopup() {
    this.isPopupOpen = true;
  }

  closePopup() {
    this.isPopupOpen = false;
  }

}
