<div class="stats-container">

  <div class="stat-item statuses">
    <ng-container *ngFor="let status of statuses; let i = index; let last = last">
      <div class="status-item">
        <div class="stat-label">{{ status._id }}</div>
        <div class="stat-value">{{ status.count }}</div>
      </div>
      <div class="separator" *ngIf="!last"></div>
      <ng-template #lastItem></ng-template>
    </ng-container>
  </div>
</div>
