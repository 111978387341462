<div class="row mb-3">
  <div *ngIf="isLoader" style="position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 1050;background-color: rgba(0, 0, 0, 0.5);overflow: auto;">
    <div class="pageLoading" style="text-align: center;color: white;font-size: 700%;">
        <div class="innerDiv" style=" position: absolute; top: 50%; left: 50%;">
            <i class="fa fa-spinner fa-spin" style="float: none;"></i>
        </div>
    </div>
</div>
  <div class="col-md-12">
    <div class="card">
      <div class="card-header bg-theme flexi-header d-flex justify-content-between">
        <div>{{formName}} Filters  </div>
        <div>
        <label class="switch">
          <input type="checkbox" (click)="changeStatus($event)" [checked]="dataStatus ? 'checked' : ''">
          <span class="slider round"></span>
        </label>
       <span class="font-weight-500 pl-2">OLD SERVICE DATA</span> 
      </div></div>
      <div class="card-body">
        <div class="row d-flex">
          <!-- first question -->
          <div *ngIf="componentData.formSubTypeId" class="form-group col-md-3">
            <label class="font-weight-500">Service Date & Time</label>
            <ng-select
              (change)="selectService($event)"
              class="custom margin"
              bindLabel="value"
              bindValue="_id"
              placeholder="Select"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              [closeOnSelect]="true"
              [(ngModel)]="selectedDateTime"
              [ngModelOptions]="{ standalone: true }"
              [items]="dateTimeOptions"
            >
            </ng-select>
          </div>

          <!-- Business Unit -->
          <div class="form-group col-md-3" *ngIf="componentData.formSubTypeId">
            <label class="font-weight-500">Business Unit</label>
            <ng-select
              (change)="selectBU($event)"
              class="custom margin"
              bindLabel="name"
              placeholder="Select"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              [closeOnSelect]="true"
              [(ngModel)]="selectedBusinessUnit"
              [ngModelOptions]="{ standalone: true }"
              [items]="businessUnitOptions"
            >
              <option
                *ngFor="let unit of businessUnitOptions"
                [value]="unit._id"
              >
                {{ unit.name }}
              </option>
            </ng-select>
          </div>
          <!-- Business unit (Plan) -->
          <div *ngIf="!componentData.formSubTypeId" class="form-group col-md-3">
            <label> Business unit (Plan) </label>
            <ng-select
              class="custom"
              bindLabel="businessUnit"
              placeholder="Business unit (Plan)"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [closeOnSelect]="true"
              [clearable]="true"
              [(ngModel)]="selectedBusinessUnit"
              [loading]="planBuUnitDropDownLoading"
              (search)="onSearchPlanBuUnit($event)"
              (scrollToEnd)="loadMorePlanBuUnitItems($event)"
              (clear)="onClearPlanBuUnitDropdown($event)"
            >
              <ng-option [value]="item" *ngFor="let item of planBuUnitDropDown">
                {{ item.orgName }}
              </ng-option>
            </ng-select>
          </div>

          <!-- status -->
          <div class="form-group col-md-2" *ngIf="componentData.formSubTypeId">
            <label for="status" class="font-weight-500">Status</label>
            <ng-select
              id="status"
              class="custom margin"
              placeholder="Select"
              appendTo="body"
              bindLabel="_id"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              [closeOnSelect]="true"
              [(ngModel)]="selectedStatus"
              [ngModelOptions]="{ standalone: true }"
              [items]="statusOptions"
            >
              <option *ngFor="let status of statusOptions" [value]="status._id">
                {{ status._id }}
              </option>
            </ng-select>
          </div>
          <!-- button -->
          <div class="form-group col-md-4 align-self-end pull-right">
            <button
              type="submit"
              class="btn btn-success m-2"
              (click)="viewResults()"
            >
              View
            </button>
            <button
              type="submit"
              class="btn btn-danger m-2"
              (click)="clearFilters()"
            >
              Clear
            </button>
            <button *ngIf='privilegeFlags.manageForm || privilegeFlags.formSupervisor || privilegeFlags.exportManageFormOnly' class="btn btn-primary ml-1" (click)="exportToExcel()">
              Export To Excel
            </button>

            <button *ngIf='privilegeFlags.manageForm || privilegeFlags.formSupervisor || privilegeFlags.exportManageFormOnly' class="btn btn-primary ml-1" (click)="exportToPDF()">
              Export To PDF
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="popup-container" *ngIf="isVisible">
  <div class="card-header bg-theme">Select a column to group the data by.</div>
  <div class="popup-content">
    <div class="form-group col-md-11">
        <label for="status" class="font-weight-500">Select an option</label>
        <ng-select
          id="status"
          class="custom margin"
          placeholder="Select"
          appendTo="body"
          bindLabel="key"
          [multiple]="false"
          [searchable]="true"
          [clearable]="true"
          [closeOnSelect]="true"
          [(ngModel)]="groupColumn"
          [ngModelOptions]="{ standalone: true }"
          [items]="columnList"
        >
          <option *ngFor="let option of columnList" [value]="option.questionId">
            {{ option.key }}
          </option>
        </ng-select>
    </div>
    <div class="d-flex justify-content-center pb-4">
      <button
        [disabled]="!groupColumn"
        class="btn btn-success ml-1"
        (click)="finalExportToPDF()"
      >
        Export to PDF
      </button>
      <button (click)="closePopup()" class="btn btn-danger ml-1">Close</button>
    </div>
  </div>
</div>
