<div
  #sidemenu
  id="sidemenu"
  class="p-3 d-none d-sm-block pt-5 sidemenu"
  *ngIf="!isView"
>
  <aside style="z-index: 999">
    <div class="form-group">
      <label class="d-block text-center">SELECT FIELD</label>
      <ng-select
        [items]="buOption"
        [loading]="buLoader"
        class="custom"
        bindLabel="key"
        placeholder="Select/Search field item"
        appendTo="body"
        [searchable]="true"
        [clearable]="true"
        [closeOnSelect]="true"
        [(ngModel)]="buId"
      >
        <ng-option [value]="item.value" *ngFor="let item of buOption">
          <span title="{{ item.key }}">{{ item.key }}</span>
        </ng-option>
      </ng-select>
    </div>

    <div class="row">
      <div class="col pr-md-3">
        <button class="btn btn-danger btn-block" (click)="clickOnCancel()">Cancel</button>
      </div>
      <div class="col pl-md-0">
        <button class="btn btn-info btn-block" (click)="viewClicked()">
          View
        </button>
      </div>
    </div>
  </aside>
</div>

<div *ngIf="!!isView">
  <div
    *ngIf="isLoader"
    style="
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1050;
      background-color: rgba(0, 0, 0, 0.5);
      overflow: auto;
    "
  >
    <div
      class="pageLoading"
      style="text-align: center; color: white; font-size: 700%"
    >
      <div class="innerDiv" style="position: absolute; top: 50%; left: 50%">
        <i class="fa fa-spinner fa-spin" style="float: none"></i>
      </div>
    </div>
  </div>
  <div class="card-header flexi-header d-flex justify-content-between">
    <h3 style="align-self: center">
      {{ "MANAGE TRANSPORT DATA" }}
      <span> - ({{ buId?.value | uppercase }})</span>
    </h3>
    <div>
      <button class="btn btn-info mt-1 mx-2" (click)="refresh()">
        Refresh
      </button>
      <button class="btn btn-warning mt-1 mx-2" (click)="exportmasterData()">
        Export data
      </button>
      <button
        class="align-self-center btn btn-secondary mt-1 mx-2"
        (click)="openImportData()"
      >
        Import data
      </button>
      <button class="btn btn-primary mt-1 mx-2" (click)="addDataField(0, null)">
        Add new data
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select
              class="custom-select"
              style="width: auto"
              name="pageSize"
              (change)="onLimitChangeUsers()"
              [(ngModel)]="usersPTable.limit"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <span *ngIf="usersPTable.isLoading">Please wait...</span>
              <input
                class="form-control ml-2"
                type="search"
                name="searchTerm"
                (search)="userssearchInput.next($event)"
                (keyup)="userssearchInput.next($event)"
                placeholder="Search here"
                [(ngModel)]="usersPTable.search"
              />
            </div>
          </div>
          <div class="table-responsive" *ngIf="buId?.type == 1">
            <table class="table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    sortable="Tier 1"
                    (sort)="onSortUsers($event)"
                  >
                    POSTAL CODE
                  </th>
                  <th
                    scope="col"
                    sortable="Tier 2"
                    (sort)="onSortUsers($event)"
                  >
                    ZONE
                  </th>
                  <th
                    scope="col"
                    sortable="Tier 3"
                    (sort)="onSortUsers($event)"
                  >
                    PUDO
                  </th>

                  <th
                    scope="col"
                    sortable="Tier 4"
                    (sort)="onSortUsers($event)"
                  >
                    WAITING TIME ZONE
                  </th>

                  <th scope="col">MAP LINK</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of usersPTable.data">
                  <td>
                    {{ item.postalCode }}
                  </td>
                  <td>{{ item?.zone }}</td>
                  <td>{{ item?.pudo }}</td>
                  <td>{{ item?.waitingTimeZone }}</td>
                  <td>
                    <a href="{{ item.mapLink }}" target="_blank">
                      {{ item.mapLink }}
                    </a>
                  </td>
                  <td>
                    <button
                      class="btn btn-add p-2 m-1"
                      style="width: 40px"
                      (click)="addDataField(1, item)"
                    >
                      <i
                        class="fa fa-pencil-square-o fa-lg"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-responsive" *ngIf="buId?.type == 2">
            <table class="table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    sortable="Tier 1"
                    (sort)="onSortUsers($event)"
                  >
                    ZONE
                  </th>
                  <th
                    scope="col"
                    sortable="Tier 2"
                    (sort)="onSortUsers($event)"
                  >
                    ZONE AREA
                  </th>
                  <th
                    scope="col"
                    sortable="Tier 3"
                    (sort)="onSortUsers($event)"
                  >
                    WAITING TIME
                  </th>

                  <th
                    scope="col"
                    sortable="Tier 4"
                    (sort)="onSortUsers($event)"
                  >
                    SEQUENCE
                  </th>

                  <th scope="col">MAX WAITING TIME FROM REPORTING</th>
                  <th scope="col">0000HR</th>
                  <th scope="col">0430HR</th>
                  <th scope="col">0530HR</th>
                  <th scope="col">0630HR</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of usersPTable.data">
                  <td>
                    {{ item?.zone }}
                  </td>
                  <td>{{ item?.zoneArea }}</td>
                  <td>{{ item?.waitingTime }}</td>
                  <td>{{ item?.sequence }}</td>
                  <td>{{ item?.maxWaitingTimeFromReporting }}</td>
                  <td>{{ item["0000Hr"] }}</td>
                  <td>{{ item["0430Hr"] }}</td>
                  <td>{{ item["0530Hr"] }}</td>
                  <td>{{ item["0630Hr"] }}</td>
                  <td>
                    <button
                      class="btn btn-add p-2 m-1"
                      style="width: 40px"
                      (click)="addDataField(1, item)"
                    >
                      <i
                        class="fa fa-pencil-square-o fa-lg"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total Records {{ usersPTable.total }}</p>
          <ngb-pagination
            [collectionSize]="usersPTable.total"
            [(page)]="usersPTable.page"
            [maxSize]="5"
            (pageChange)="onPageChangeUsers($event)"
            [pageSize]="usersPTable.limit"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #AddDataModal let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 100vh">
        <div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header pt-4">
              <h3>
                {{ isFieldEdited ? "UPDATE DATA" : "ADD DATA" }}
                <span> - ({{ buId?.value | uppercase }})</span>
              </h3>
              <button
                type="button"
                class="btn no-border"
                aria-label="Close"
                (click)="c('Cross click')"
              >
                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
              </button>
            </div>
            <div class="card-body">
              <form [formGroup]="transportFieldForm">
                <div class="row">
                  <div
                    class="col-md-12"
                    *ngFor="let key of transportFieldForm.controls | keyvalue"
                  >
                    <div class="form-group">
                      <label
                        class="font-weight-bold red-heading"
                        [for]="key.key"
                        >{{ key.key }}</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        [formControlName]="key.key"
                        [id]="key.key"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <button
                      class="btn btn-add btn-block p-1 m-2"
                      [disabled]="isLoading"
                      type="submit"
                      [disabled]="!transportFieldForm.valid"
                      (click)="onSubmitDataField()"
                    >
                      <i
                        class="fa"
                        [ngClass]="{
                          'fa-spin fa-spinner': isLoading,
                          '': !isLoading
                        }"
                      ></i>
                      {{ isFieldEdited ? "Update" : "Add Field" }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #importData let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Import Master Data</h4>
    <div class="d-flex">
      <button (click)="downloadSampleCSV()" class="btn btn-secondary mx-2">
        Download Sample
      </button>
      <button
        ngbAutofocus
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <form
          [formGroup]="importDataForm"
          class="d-flex flex-wrap"
          (ngSubmit)="uploadImportMasterData()"
        >
          <div class="bg-info header-buttons p-2 mb-2 rounded w-100">
            <input
              type="file"
              name="csv"
              class="btn-info rounded"
              accept=".csv"
              (change)="importDatafile($event.target.files)"
            />
          </div>
          <button
            type="button"
            class="btn btn-success mx-auto"
            (click)="uploadImportMasterData()"
          >
            Upload
          </button>
        </form>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('Cross click')"
    >
      Close
    </button>
  </div>
</ng-template>
