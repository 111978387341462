<div class="align-self-end" style="width: max-content;
    float: right;">
  <button class="btn btn-info m-2" (click)="togglePanel()">Filter Column &emsp; <i
      class="fa fa-columns" aria-hidden="true"></i>
  </button>
</div>

<div class="table-responsive" *ngIf='tableHeaders.length'>
  <table class="table table-bordered table-hover">
    <thead>
      <tr>
        <th [hidden]="!isColumnVisible(header)" class="card-header bg-theme" *ngFor="let header of tableHeaders">{{ header }}
          <!-- <input type="text"
          placeholder="Search"
          (input)="onSearch($event, header, formSubmissionListData[0])" /> -->

        </th>
        <th *ngIf='privilegeFlags.manageForm || privilegeFlags.formSupervisor || hasWorkflow' class="card-header bg-theme">Action</th> <!-- Action column header -->

      </tr>
      <tr>
        <th [hidden]="!isColumnVisible(header)" *ngFor="let header of tableHeaders">
          <input type="text"
          placeholder="Search"
          [(ngModel)]="searchValues[header]"
          (input)="onSearch($event, header, formSubmissionListData[0])" />
        </th>
      </tr>
    </thead>
    <tbody *ngIf="tableData.length > 0; else noData">
      <tr *ngFor="let row of tableData | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: total }">
        <td [hidden]="!isColumnVisible(header)" *ngFor="let header of tableHeaders">{{ row[header] }}</td>
        <td class="d-flex" *ngIf='privilegeFlags.manageForm || privilegeFlags.formSupervisor || hasWorkflow'>
          <button *ngIf='privilegeFlags.manageForm || privilegeFlags.formSupervisor' class="btn btn-success m-1" (click)="onEdit(row)">Edit</button>
          <button *ngIf='privilegeFlags.manageForm || privilegeFlags.formSupervisor' class="btn btn-warning m-1" (click)="onCancel(row)">Cancel</button>
          <button class="btn btn-info m-1" *ngIf="hasWorkflow" (click)="workFlowCall(row)">WorkFlow</button>
        </td> <!-- Action column with buttons -->
      </tr>

    </tbody>
    <ng-template #noData>
      <tr>
        <td [attr.colspan]="tableHeaders.length + 1" class="text-center">No data available</td>
      </tr>
    </ng-template>
  </table>

</div>

<!-- Pagination Controls -->
<div *ngIf="tableData.length > 0" class="d-flex justify-content-center mt-3">
  <pagination-controls
  (pageChange)="pageChanged($event)"
  (pageBoundsCorrection)="pageChanged($event)"
  maxSize="9">
</pagination-controls>
</div>

<ng-template #updateSettings let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 0px; min-height: 100vh">
        <div>
          <div class="card w-100">
            <div class="card-header flexi-header">
              <h3>COLUMN VISIBILITY SETTINGS</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Column Name</th>
                            <th scope="col">
                              Status
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr *ngFor="let header of tableHeaders">
                            <td style="word-break: break-all">
                              <p>{{header}}</p>
                            </td>

                            <td>
                              <input type="checkbox" [(ngModel)]="pendingColumnVisibility[header]" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="d-flex justify-content-between">
                          <button class="btn btn-clear btn-block mr-1" (click)="onCloseColumnForm()">
                            Close
                          </button>
                          <button style="margin: 2px;" class="btn btn-add btn-block"
                            (click)="onSubmitColumnForm()">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
