import {
  Component,
  Output,
  Input,
  EventEmitter,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BussinessUnitService as BusinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

interface DateTimeOption {
  value: string;
  _id: string;
}

interface BusinessUnitOption {
  _id: string;
  name: string;
}

@Component({
  selector: 'filter-display',
  templateUrl: './filter-display.html',
  styleUrls: ['./filter-display.scss'],
})
export class FilterDisplayComponent implements OnInit {
  selectedDateTime: string = '';
  selectedBusinessUnit: any = '';
  selectedStatus: any = null;
  isLoader: boolean = false;
  dateTimeOptions: DateTimeOption[] = [];
  businessUnitOptions: BusinessUnitOption[] = [
  ];
  dataStatus: true;
  @Input() statusOptions: string[] = ['Active', 'Inactive', 'Pending'];

  @Input() customFormId: string = null;
  @Input() formName: string = null;
  @Input() componentData: any = null;
  @Input() firstRow: any = null;

  @Output() filterCall = new EventEmitter<any>();

  @Output() exportCall = new EventEmitter<any>();


  isVisible: boolean = false;
  columnList: any=[];
  groupColumn: any = '';
  allServiceId: any = [];

  businessUnitPlanPTable: {
    sortBy: string;
    sortWith: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
    filter: { type: string };
  };
  planBuUnitDropDown = [];
  planBuUnitDropDownLoading = false;
  excludeFilter = ['Submission']
  privilegeFlags

  constructor(
    private customFromService: CustomFormService,
    private buUnit: BusinessUnitService,
    private toastService: ToastrService
  ) {
    this.businessUnitPlanPTable = {
      sortBy: 'createdAt',
      sortWith: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',
      filter: {
        type: 'plan',
      },
    };
  }

  ngOnInit(): void {
    const data =  localStorage.getItem("privilegeFlags");
    this.privilegeFlags = JSON.parse(data);
    if (!this.componentData.formSubTypeId) {
      this.loadBusinessPlanUnit();
    }else{
      this.loadTwoTierBusinessUnit();
    }
    setTimeout(() => {
      this.clearFilters();
    },1000);
 
  }

  loadTwoTierBusinessUnit(){
    this.customFromService
    .getTransportBusinessUnit()
    .subscribe((data) => {
      this.businessUnitOptions = data?.data;
    });
  }
  editDateTime() {
    // Add your logic to edit date and time here
  }

  exportToExcel() {
    this.exportCall.emit({
      type: 'CSV',
    });

  }

  finalExportToPDF() {
    this.isLoader = true;
    this.exportCall.emit({
      type: 'PDF',
      groupBy: this.groupColumn.questionId,
    });
    setTimeout(() => {
      this.isLoader = false;
      this.closePopup();
      this.toastService.success("Data exported successfully", 'Success');
    }, 100);
  }

  exportToPDF() {
    this.isLoader = true;
    if(this.firstRow){
   this.columnList = Object.keys(this.firstRow)
    .filter(key => this.firstRow[key].questionId)
    .map(key => ({
        key: key,
        questionId: this.firstRow[key].questionId
    }));

    this.isVisible = true;
  }else {
    this.toastService.error('No data found', 'Error')
  }
    this.isLoader = false;
  }

  closePopup(): void {
    this.isVisible = false;
  }

  changeStatus(event): void {
      this.dataStatus = event?.target?.checked;
      this.prepareServiceTimeDropDown();
      setTimeout(() => {
        this.clearFilters();
      }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.componentData && this.componentData.formSubTypeId) {
      this.prepareServiceTimeDropDown();
    }

    if (changes.statusOptions) {
      this.statusOptions = changes.statusOptions.currentValue;
      this.statusOptions = this.statusOptions?.filter((s: any)=> !this.excludeFilter.includes(s._id))
    }

    if(changes.firstRow){
      this.firstRow =  changes.firstRow.currentValue;
      console.log(this.firstRow)
    }
  }

  prepareServiceTimeDropDown() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
   const fetchServicedata = !this.dataStatus ? this.customFromService.serviceTimeDropDown(this.componentData.formSubTypeId, timeZone) :
    this.customFromService.serviceTimeDropDownAll(this.componentData.formSubTypeId, timeZone)
    
    fetchServicedata.subscribe((data) => {
        this.dateTimeOptions = data?.data;
        this.selectedDateTime = ""
        this.allServiceId = this.dateTimeOptions.map(item => item._id);
        if(this.selectedDateTime){
        this.viewResults()
        }
      });
  }

  selectService(event) {
    this.selectedDateTime = event?._id;
  }
  selectBU(event) {
    console.log(event);
  }

  editStatus() {
    // Add your logic to edit status here
  }

  viewResults() {
    console.log(
      'Selected DateTime:',
      this.selectedDateTime,
      this.selectedBusinessUnit,
      this.selectedStatus
    );
    this.filterCall.emit({
      serviceId: this.selectedDateTime? [this.selectedDateTime]: this.allServiceId,
      buId: this.selectedBusinessUnit?._id,
      status: this.selectedStatus?._id,
      currentPage:1
    });
    // Add your logic to view the results here
  }

  clearFilters() {
    this.selectedBusinessUnit = '';
    this.selectedStatus = '';
    this.selectedDateTime = '';
    this.filterCall.emit({
      serviceId: this.selectedDateTime? [this.selectedDateTime]: this.allServiceId,
      buId: this.selectedBusinessUnit,
      status: this.selectedStatus?._id,
      currentPage:1,
      isClear: true
    });
  }

  loadBusinessPlanUnit() {
    this.planBuUnitDropDownLoading = true;
    const { data, ...rest } = this.businessUnitPlanPTable;
    const subscribe = this.buUnit
      .getBussinesUnit(rest, true)
      .pipe(
        finalize(() => {
          this.planBuUnitDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { recordsTotal, businessUnitList } = response;
          this.businessUnitPlanPTable.data = businessUnitList;
          this.planBuUnitDropDown = businessUnitList;
        },
        (error) => {
          this.businessUnitPlanPTable.data = [];

          // this.toastService.error('No list found');
        }
      );
  }

  onSearchPlanBuUnit(value) {
    this.businessUnitPlanPTable.limit = 10;
    this.businessUnitPlanPTable.search = value.term;

    this.loadBusinessPlanUnit();
  }
  loadMorePlanBuUnitItems(value) {
    this.businessUnitPlanPTable.limit = this.businessUnitPlanPTable.limit + 10;
    this.loadBusinessPlanUnit();
  }

  onClearPlanBuUnitDropdown(value) {
    if (value === undefined) {
      this.businessUnitPlanPTable.search = '';
      this.loadBusinessPlanUnit();
      this.planBuUnitDropDown = [];
      this.businessUnitPlanPTable.data = [];
    }
  }
}
