<div class="card mt-3">
  <div
    class="align-items-center bg-theme card-header d-flex justify-content-between"
  >
    CREATE SUBMISSION
    <button (click)="goToManage()" class="btn btn-info">Back</button>
  </div>
  <div
    *ngIf="isLoader"
    style="
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1050;
      background-color: rgba(0, 0, 0, 0.5);
      overflow: auto;
    "
  >
    <div
      class="pageLoading"
      style="text-align: center; color: white; font-size: 700%"
    >
      <div class="innerDiv">
        <i class="fa fa-spinner fa-spin" style="float: none"></i>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="form-group col-md-4">
          <label for="service-name">Service Name</label>
          <input
            type="text"
            id="service-name"
            [value]="serviceName"
            disabled
            class="form-control"
            readonly
          />
        </div>

        <div class="form-group col-md-4">
          <label for="staff-id">Staff ID*</label>
          <mat-form-field class="full-width">
            <input
              matInput
              [formControl]="staffIdControl"
              [matAutocomplete]="auto"
              placeholder="Search by staffId"
              (input)="onStaffIdChange($event)"
              style="border: 0"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="onOptionSelected($event)"
            >
              <mat-option
                *ngFor="let id of filteredStaffIds"
                [value]="id.staffId"
              >
                {{ id.name }} ({{ id.staffId }})
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="card">
        <div>
          <div class="bg-theme card-header pt-4">
            <h5 style="display: inline">
              <strong class="mr-1">1 </strong> &emsp; {{ firstSection }}
            </h5>
          </div>
          <div class="card-body">
            <div>
              <div
                class="box col-sm-6"
                *ngFor="let item of viewQuestions; let i = index"
              >
                <div class="d-flex pt-2">
                  <strong class="mr-1"
                    >{{ i + 1 }}&nbsp;
                    <span *ngIf="item.required" class="required">*</span>
                  </strong>
                  <div class="box-heading" style="word-break: break-word">
                    &emsp;
                    <p [innerHTML]="item?.question | safeHtml"></p>
                  </div>
                </div>
                <div class="box-body" *ngIf="item.type === 1">
                  <textarea
                    matInput
                    rows="2"
                    style="width: 100%"
                    [(ngModel)]="item.answer"
                    maxlength="{{ !!item?.maxlength ? item?.maxlength : 500 }}"
                    [disabled]="isFormSubmitted"
                  ></textarea>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>
                <div *ngIf="item.type === 2">
                  <div
                    class="form-group answers-options"
                    [ngClass]="
            item.optionsView == 0
              ? 'image-container-vertically'
              : 'image-container-horizontally'
          "
                  >
                    <div class="box" *ngFor="let option of item.options">
                      <div class="card-body" *ngIf="!!option.imageSrc">
                        <img
                          [src]="option?.imageSrc"
                          crossorigin="anonymous"
                          class="image-size"
                          id="4"
                          alt="Image Preview"
                        />
                      </div>
                      <input
                        type="radio"
                        id="{{ option._id }}"
                        name="{{ item._id }}"
                        [value]="option"
                        [(ngModel)]="item.answer"
                        (change)="setChild(item, option)"
                        class="card-header-flexi"
                        [disabled]="isFormSubmitted"
                      />
                      <label for="consolidated">{{ option.value }}</label>
                    </div>
                  </div>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>
                <div class="" *ngIf="item.type === 3">
                  <div
                    [ngClass]="
            item.optionsView == 0
              ? 'image-container-vertically'
              : 'image-container-horizontally'
          "
                  >
                    <div class="box" *ngFor="let option of item.options">
                      <div class="card-body" *ngIf="!!option.imageSrc">
                        <img
                          [src]="option?.imageSrc"
                          class="image-size"
                          crossorigin="anonymous"
                          alt="Image Preview"
                        />
                      </div>
                      <input
                        type="radio"
                        id="{{ option._id }}"
                        name="{{ item._id }}"
                        [value]="option"
                        [(ngModel)]="item.answer"
                        (change)="setChild(item, option)"
                        class="card-header-flexi"
                        [disabled]="isFormSubmitted"
                      />
                      <label for="{{ option._id }}">{{ option.value }}</label>
                    </div>
                    <br />
                    <div *ngIf="isFormSubmitted && item?.explanation">
                      <span>Answer Explanation:</span>
                      <p [innerHTML]="item?.explanation | safeHtml"></p>
                    </div>
                  </div>
                </div>
                <div class="card-body" *ngIf="item.type === 4">
                  <div
                    [ngClass]="
            item.optionsView == 0
              ? 'image-container-vertically'
              : 'image-container-horizontally'
          "
                    style="overflow: auto"
                  >
                    <div
                      class="question-radio-inline box"
                      *ngFor="let option of item.options; let i = index"
                    >
                      <label
                        for="{{ option._id }}"
                        class="card"
                        style="border-radius: 10px"
                      >
                        <img
                          *ngIf="!!option.imageSrc"
                          class="image-size"
                          crossorigin="anonymous"
                          src="{{ option.imageSrc }}"
                          alt=""
                          style="border-radius: 10px 10px 0px 0px"
                          (click)="item.selected = i"
                        />
                        <div
                          class="card-container"
                          [ngStyle]="{ width: option.imageSrc ? '' : '150px' }"
                          style="
                            background-color: #6eb96e;
                            display: flex;
                            justify-content: space-between;
                            border-radius: 0px 0px 10px 10px;
                            padding: 5px;
                          "
                          (click)="selectPolling(item._id, option)"
                        >
                          <h4><b>Select</b></h4>
                          <img
                            crossorigin="anonymous"
                            style="
                              width: 12%;
                              min-width: 30px;
                              height: 1%;
                              margin: 3px;
                            "
                            src="{{
                    './assets/images/' +
                      (option.answer ? 'like1' : 'like0') +
                      '.png'
                  }}"
                            alt=""
                            height="10px"
                          />
                        </div>
                      </label>
                      <h4
                        style="text-align: center"
                        [ngClass]="{ pollingSelect: item.selected === i }"
                        (click)="item.selected = i"
                      >
                        <b>{{ option.value }}</b>
                      </h4>
                      <span class="text-success" *ngIf="!!option.percentage"
                        >{{ option.percentage }} %</span
                      >
                    </div>
                  </div>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>

                <div class="" *ngIf="item.type === 5">
                  <div
                    class="form-group answers-options"
                    [ngClass]="
            item.optionsView == 0
              ? 'image-container-vertically'
              : 'image-container-horizontally'
          "
                  >
                    <div class="box" *ngFor="let option of item.options">
                      <div *ngIf="option.imageSrc" class="card-body">
                        <img
                          [src]="option.imageSrc"
                          class="image-size"
                          id="4"
                          alt="Image Preview"
                        />
                      </div>
                      <input
                        type="checkbox"
                        [(ngModel)]="option.answer"
                        name="{{ option._id }}"
                        id="{{ option._id }}"
                        (change)="setChild(item, option)"
                        [disabled]="isFormSubmitted"
                      />
                      <label for="consolidated">{{ option.value }}</label>
                    </div>
                  </div>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>

                <div class="box-body" *ngIf="item.type === 6">
                  <div>
                    <div *ngIf="item.answer" class="signature-div">
                      <img [src]="item.answer" class="signature-img" />
                    </div>
                    <button
                      mat-button
                      class="mat-sign-button"
                      (click)="openDialog(item)"
                      [disabled]="isFormSubmitted"
                    >
                      Signature
                    </button>
                    <mat-icon
                      aria-hidden="false"
                      aria-label="Example home icon"
                      fontIcon="home"
                    ></mat-icon>
                  </div>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>

                <div class="box-body" *ngIf="item.type === 8">
                  <div class="d-flex flex-wrap answers-options">
                    <div
                      class="box form-group flex-fill"
                      style=" width: 100%"
                    >
                      <textarea
                        class="form-control"
                        style="width: 100%"
                        rows="2"
                        [(ngModel)]="item.answer"
                        [ngModelOptions]="{standalone: true}"
                        [maxlength]="item.maxlength"
                        [disabled]="isFormSubmitted"
                        (keydown)="onInputChangeShortText($event, item.isNumeric)"
                        (paste)="onPaste($event, item.isNumeric, item.maxlength)"
                        [ngStyle]="{'resize': item.isNumeric ? 'none' : 'both'}"
                      ></textarea>
                    </div>
                  </div>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>

                <div class="box-body" *ngIf="item.type === 9">
                  <div class="d-flex flex-wrap answers-options">
                    <div
                      class="box form-group flex-fill"
                      style="margin: 1rem 1rem; width: 44%"
                    >
                      <input
                        type="number"
                        class="form-control"
                        [(ngModel)]="item.answer"
                        [disabled]="isFormSubmitted"
                      />
                    </div>
                  </div>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>

                <div class="box-body" *ngIf="item.type === 10">
                  <div *ngFor="let option of item.dateTime">
                    <div
                      *ngIf="item.dateTime?.length === 2 && option === 2"
                      class="box"
                    >
                      <div class="d-flex flex-wrap answers-options">
                        <div class="form-group flex-fill" style="margin: 1rem">
                          <label for="my-input">Date</label>
                          <input
                            id="my-input"
                            class="form-control"
                            type="date"
                            [(ngModel)]="item.answer['date']"
                            [disabled]="isFormSubmitted"
                          />
                        </div>
                      </div>
                      <div style="margin-left: 10px" class="box">
                        <label>Time</label>
                        <ngb-timepicker
                          [(ngModel)]="item.answer['time']"
                          [meridian]="true"
                          [hourStep]="hourStep"
                          [minuteStep]="minuteStep"
                        ></ngb-timepicker>
                      </div>
                      <br />
                      <div *ngIf="isFormSubmitted && item?.explanation">
                        <span>Answer Explanation:</span>
                        <p [innerHTML]="item?.explanation | safeHtml"></p>
                      </div>
                    </div>
                    <div
                      class="box form-group"
                      *ngIf="item.dateTime?.length === 1 && option === 1"
                    >
                      <div
                        class="d-flex flex-wrap answers-options"
                        *ngIf="option === 1"
                      >
                        <div class="form-group flex-fill" style="margin: 1rem">
                          <label for="my-input">Date</label>
                          <input
                            id="my-input"
                            class="form-control"
                            type="date"
                            [(ngModel)]="item.answer['date']"
                            [disabled]="isFormSubmitted"
                          />
                        </div>
                      </div>
                      <br />
                      <div *ngIf="isFormSubmitted && item?.explanation">
                        <span>Answer Explanation:</span>
                        <p [innerHTML]="item?.explanation | safeHtml"></p>
                      </div>
                    </div>
                    <div
                      style="margin-left: 10px"
                      class="box"
                      *ngIf="option === 2 && item.dateTime?.length === 1"
                    >
                      <div style="margin-left: 10px" class="box">
                        <label>Time</label>
                        <ngb-timepicker
                          [(ngModel)]="item.answer['time']"
                          [meridian]="true"
                          [hourStep]="hourStep"
                          [minuteStep]="minuteStep"
                        ></ngb-timepicker>
                      </div>
                      <br />
                      <div *ngIf="isFormSubmitted && item?.explanation">
                        <span>Answer Explanation:</span>
                        <p [innerHTML]="item?.explanation | safeHtml"></p>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>

                <div class="box-body" *ngIf="item.type === 11">
                  <div class="box form-group ng-select-img-container">
                    <ng-select
                      #mySelect
                      class="custom"
                      placeholder="Select Options"
                      bindLabel="value"
                      appendTo="body"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="false"
                      [(ngModel)]="item.answer"
                      [readonly]="isFormSubmitted"
                      (change)="setChild(item, item.answer, i)"
                    >
                      <ng-option
                        [value]="choice"
                        *ngFor="let choice of item.options"
                      >
                        <img
                          src="{{ choice.imageSrc }}"
                          crossorigin="anonymous"
                          class="dropdown-img"
                          *ngIf="choice.imageSrc"
                        />
                        &nbsp;{{ choice.value }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>

                <div class="box-body" *ngIf="item.type === 12">
                  <div class="box form-group">
                    <input
                      id="my-input"
                      class="form-control-file"
                      type="file"
                      (change)="fileUpload($event, item)"
                      [disabled]="isFormSubmitted"
                    />
                  </div>
                  Preffered formats are -
                  <span
                    *ngFor="let fileType of item?.options; let index = index"
                  >
                    {{ fileType.value }}<span
                      *ngIf="item.options?.length != index + 1"
                      >,
                    </span>
                  </span>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>

                <div class="box-body" *ngIf="item.type === 13">
                  <div class="d-flex">
                    <ngb-rating
                      [max]="item.value"
                      [(rate)]="item.answer"
                      [readonly]="isFormSubmitted"
                    ></ngb-rating>
                  </div>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>

                <div class="box-body" *ngIf="item.type === 14">
                  <div class="row">
                    <div class="col-md-6">
                      <ng-select
                        class="custom"
                        bindLabel="name"
                        (change)="multiSelect($event, item)"
                        placeholder="Select Users"
                        [multiple]="true"
                        [searchable]="true"
                        [(ngModel)]="user"
                        [clearable]="true"
                        [disabled]="isFormSubmitted"
                      >
                        <ng-option
                          [value]="choice._id"
                          *ngFor="let choice of typeFourteenQuestion"
                        >
                          {{ choice.name }}
                        </ng-option>
                      </ng-select>
                    </div>
                  </div>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>

                <div class="box-body" *ngIf="item.type === 15">
                  <div cdkDropList (cdkDropListDropped)="drop($event, item)">
                    <div
                      *ngFor="let imagObj of item.images"
                      cdkDrag
                      [cdkDragDisabled]="isFormSubmitted"
                    >
                      <div class="img-thumbnail">
                        <div *ngIf="imagObj.src">
                          <img
                            [src]="imagObj.src"
                            id="4"
                            alt="Image Preview"
                            class="sequence-image"
                          />
                        </div>
                        <p>{{ imagObj.value }}</p>
                      </div>

                      <br />
                    </div>
                  </div>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>

                <div class="box-body" *ngIf="item.type === 21">
                  <div class="d-flex"></div>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>

                <div class="box-body" *ngIf="item.type === 22">
                  <div class="d-flex"></div>
                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>
                </div>

                <div class="box-body-16" *ngIf="item.type === 16">
                  <div
                    [ngStyle]="{ position: isPopupOpen ? 'sticky' : 'relative' }"
                  >
                    <div
                      class="full-icon"
                      (click)="openPopup(item.imageSrc)"
                      ngbTooltip="Expand"
                      placement="bottom"
                    >
                      <i class="fa fa-expand" aria-hidden="true"></i>
                    </div>
                    <img
                      #myImage
                      [src]="item.imageSrc"
                      width="100%"
                      alt="Image Preview"
                      (click)="onImageClick($event, viewQuestions, i)"
                    />
                    <div
                      *ngFor="let item of typeSixteenImageField; let j = index"
                      [style.left.%]="item?.coordinates?.x"
                      [style.top.%]="item?.coordinates?.y"
                      class="circle"
                    >
                      <span class="text">{{ j + 1 }}</span>
                    </div>
                    -
                  </div>

                  <br />
                  <div *ngIf="isFormSubmitted && item?.explanation">
                    <span>Answer Explanation:</span>
                    <p [innerHTML]="item?.explanation | safeHtml"></p>
                  </div>

                  <div
                    class="row imgSrc"
                    *ngIf="typeSixteenImageField.length > 0"
                  >
                    <strong class="pb-3">Spot descriptions</strong>
                    <div class="table">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="w-20">SNO</th>
                            <th>DESCRIPTIONS</th>
                            <th>&ensp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                    let field of typeSixteenImageField;
                    let index = index
                  "
                          >
                            <td class="srNo">{{ index + 1 }}</td>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                [(ngModel)]="field.value"
                                [disabled]="isFormSubmitted"
                              />
                            </td>
                            <td
                              *ngIf="typeSixteenImageField.length >= 1"
                              class="cancel-button"
                            >
                              <img
                                class="image"
                                alt=""
                                (click)="conditionQuestionDelete(index)"
                                src="./assets/images/cancel.png"
                                height="20px"
                                crossorigin="anonymous"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    *ngIf="viewQuestions.length == 0"
                    style="text-align: center"
                  >
                    No questions to show
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="my-4">
        <button
          [disabled]="isFormSubmitted"
          (click)="saveDetails()"
          class="btn btn-success"
        >
          Add
        </button>
        <div style="float: right">
          <div class="card">
            <div class="card-body">
              <button
                [disabled]="isFormSubmitted"
                (click)="exportFileFormat()"
                class="btn btn-primary mx-2"
              >
                Download Format
              </button>
              <input
                type="file"
                name="csv"
                class="btn-info rounded mx-2"
                [disabled]="isFormSubmitted"
                accept=".csv"
                (change)="importBookingFile($event.target.files)"
              />
              <button
                [disabled]="isFormSubmitted"
                (click)="importBookingData()"
                class="btn btn-secondary mx-2"
              >
                Import
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="submissionList.length">
    <div class="bg-theme card-header">
      <span class="eventTitle">SUBMISSION LIST</span>
    </div>
    <div class="card-body p-0">
      <!-- Draft View -->
      <div>
        <indent-draft-list
          [submissionList]="submissionList"
          [customFormId]="customFormId"
          (onSave)="onSave($event)"
        >
        </indent-draft-list>
      </div>
    </div>
  </div>
</div>
