<div class="card">
  <div class="card-header align-items-center bg-theme d-flex justify-content-between">
    <div>
    <h5 class="m-0">VIEW SHIFTS</h5>
    <p class="m-0">Selected Business Unit : {{ buId?.orgName }}</p>
  </div>
  <div>
    <label class="switch">
      <input type="checkbox" (click)="changeStatus($event)" [checked]="hideCancelShifts ? 'checked' : ''">
      <span class="slider round"></span>
    </label>
   <span class="font-weight-500 pl-2">Hide CANCELLED Shifts</span> 
  </div>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-md-5">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label for="" class="font-weight-500">Select Day</label><br>
              <button
                *ngFor="let i of dayArr"
                class="dayButton"
                [class.selected]="i === selectedDay"
                (click)="getSelectedWeek1(i)"
              >
                {{ i.day }}
              </button>
            </div>

            <div
              *ngIf="dayShifts?.length > 0; then thenBlock; else elseBlock"
            ></div>
            <ng-template #thenBlock>
              <div *ngFor="let i of dayShifts; let index = i">
                <div
                  class="shiftTab my-2"
                  *ngIf="
                    (!!hideCancelShifts ? i.status != 2 : ( (i.isOff == false && i.isRest == false) ||
                    (i.isRecalled == true && i.isRecallAccepted == 2)))
                  "
                  [class.selectedShift]="
                    i === selectedShift && i.isAssignShift == false
                  "
                  [class.selectedShiftAssign]="
                    i === selectedShift && i.isAssignShift == true
                  "
                  [class.assign]="i.isAssignShift == true"
                  (click)="getListShift(i)"
                >
                  <h6 class="m-0 my-1" *ngIf="i.isAssignShift == true">
                    <span *ngIf="i.requestedUsers.length == 0">{{
                      i.confirmedStaffs[0]?.name
                    }}</span>
                    <span *ngIf="i.requestedUsers.length != 0">{{
                      i.requestedUsers[0].userId.name
                    }}</span>
                  </h6>
                  <div class="d-flex justify-content-between">
                    <div class="text-secondary">
                      <ng-container *ngIf="i.subSkillSets?.length">
                        <p class="m-0" *ngFor="let j of i.subSkillSets">
                          {{ j.skillSetId.name | titlecase }} >
                          {{ j.name | titlecase }}
                        </p>
                        <p *ngIf="i.isSplitShift">split shift</p>
                      </ng-container>
                      <ng-container *ngIf="i.mainSkillSets?.length">
                        <p class="m-0" *ngFor="let j of i.mainSkillSets">
                          {{ j.name | titlecase }} > _
                        </p>
                      </ng-container>
                    </div>
                    <div class="text-info" *ngIf="i.isRecalled">
                      {{
                        i.isOff == true
                          ? "Recalled on Off Day"
                          : "Recalled on Rest Day"
                      }}
                    </div>
                    <div class="text-secondary">

                      <p class="m-0">
                        {{ i.startTime | date: "HH:mm" }} to
                        {{ i.endTime | date: "HH:mm" }}
                      </p>
                      <p class="m-0" *ngIf="i.isSplitShift">
                        {{ i.splitShiftStartTime | date: "HH:mm" }} to
                        {{ i.splitShiftEndTime | date: "HH:mm" }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-end"
                    *ngIf="i.isAssignShift == false"
                  >
                    <div class="badge badge-pill bg-theme text-white">
                      {{ i.staffNeedCount }}/{{ i.backUpStaffNeedCount }}
                    </div>
                  </div>
                  <div
                    class="badge badge-danger badge-pill"
                    *ngIf="i.status == 2"
                  >
                    Cancelled
                  </div>
                </div>
              </div>

              <div class="col-md-12 p-0">
                <div class="mt-4 redButton rounded text-center3">
                  <b>List of Staff on Rest/Off</b>
                </div>
                <div *ngFor="let i of dayShifts; let index = i">
                  <div
                    class="shiftTab my-2"
                    *ngIf="
                      (i.isOff == true || i.isRest == true) &&
                      (i.isRecallAccepted == 1 || i.isRecallAccepted == 3)
                    "
                    [class.selectedShift]="
                      i === selectedRecall && i.isAssignShift == true
                    "
                  >
                    <div
                      class="d-flex align-items-center justify-content-between mt-1"
                      *ngFor="let j of i.confirmedStaffs"
                    >
                      <span>{{ j.staffId }}</span>
                      <span>{{ j.name }}</span>
                      <button class="btn btn-primary" (click)="changeOff(i)">
                        {{ i.isOff == true ? "OFF" : "REST" }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #elseBlock>
              <h5 class="text-muted">No items found</h5>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card" *ngIf="this.dayShifts?.length > 0">
          <div class="card-header flexi-header">
            <h3>CREATE SHIFT</h3>
          </div>
          <div class="card-body">
            <form [formGroup]="ShiftForm" *ngIf="!cfr">
              <div class="mb-3 col-md-12">
                <div class="form-input mb-2">Business Unit</div>
                <input
                  class="form-control disabled"
                  formControlName="buName"
                  readonly
                />
              </div>
              <div class="mb-3 col-md-12">
                <div class="form-input mb-2">Date</div>
                <input
                  class="form-control disabled"
                  formControlName="date"
                  readonly
                />
              </div>
              <div class="mb-3 col-md-12">
                <div class="form-input mb-2">Time</div>
                <div class="col-md-12 no-padding flexDisplay">
                  <input
                    class="form-control mr-2"
                    formControlName="startTime"
                    readonly
                  />
                  <input
                    class="form-control ml-2"
                    formControlName="endTime"
                    readonly
                  />
                </div>
                <div
                  class="col-md-12 no-padding flexDisplay mb-1"
                  *ngIf="isSplitTime"
                >
                  <input
                    class="form-control mr-2"
                    formControlName="splitstartTime"
                    readonly
                  />
                  <input
                    class="form-control ml-2"
                    formControlName="splitendTime"
                    readonly
                  />
                </div>

              </div>
              <div class="mb-3 col-md-12">
                <div class="form-input col-md-12 no-padding mb-2">
                  Skillsets & Speciality
                </div>
                <ng-container *ngIf="skillLabel.length">
                  <div
                    class="redButton mr-2 mb-2 d-inline-block"
                    *ngFor="let i of skillLabel"
                  >
                    {{ i[0] }} > {{ i[1] }}
                  </div>
                </ng-container>
              </div>
              <div class="mb-3 col-md-12">
                <div class="form-input mb-2">Reports To</div>
                <input
                  class="form-control"
                  formControlName="reportLocationId"
                  readonly
                />
              </div>
              <div class="mb-3 col-md-12" *ngIf="hideDivForAssign">
                <div class="form-input mb-2">Planned Staff Count</div>
                <div class="col-md-12 no-padding flexDisplay">
                  <div>
                    <input
                      class="form-control pr-2"
                      formControlName="staffNeedCount"
                      readonly
                    />
                    <div class="pr-2">Confirmed</div>
                  </div>
                  <div>
                    <input
                      class="form-control pl-2"
                      formControlName="backUpStaffNeedCount"
                      readonly
                    />
                    <div class="pl-2">Standby</div>
                  </div>
                </div>
              </div>
              <div class="mb-3 col-md-12" *ngIf="hideDivForAssign">
                <div class="redButton col-md-12 no-padding mb-2">
                  <b>Staff Booked</b>
                </div>
                <div class="col-md-12 no-padding flexDisplay">
                  <div>
                    <input
                      class="form-control pr-2"
                      formControlName="backUpStaffNeedCountBooked"
                      readonly
                    />
                    <div class="pr-2">Confirmed</div>
                  </div>
                  <div>
                    <input
                      class="form-control pl-2"
                      formControlName="backUpStaffNeedCountSBBooked"
                      readonly
                    />
                    <div class="pl-2">Standby</div>
                  </div>
                </div>
              </div>
              <div class="forView" *ngIf="hideBtn">
                <div
                  class="col-md-12 mb-3"
                  *ngIf="showCancelDiv && hideDivForAssign"
                >
                  <ngb-accordion #a="ngbAccordion">
                    <ngb-panel id="custom-panel-1">
                      <ng-template ngbPanelHeader>
                        <!-- let-opened="" -->
                        <button
                          ngbPanelToggle
                          type="button"
                          class="btn btn-block bg-theme"
                          data-toggle="collapse"
                          data-target="#adjustShift"
                          *ngIf="adjustShiftbtn == false"
                        >
                          Adjust Shift
                          <i class="material-icons text-white down">
                            expand_more
                          </i>
                        </button>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        <div id="adjustShift">
                          <div class="row" *ngIf="adjustShiftbtn == false">
                            <div class="col-md-6">
                              <label>Planned staff</label>
                              <input
                                class="form-control"
                                formControlName="staffNeedCount"
                                readonly
                              />
                            </div>
                            <div class="col-md-6">
                              <label>Adjust to</label>
                              <input
                                class="form-control"
                                formControlName="adjust"
                              />
                            </div>
                            <div class="col-md-12">
                              <button
                                class="btn btn-block btn-success mt-3"
                                (click)="saveAdjust()"
                              >
                                Submit changed shift
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </ngb-panel>
                  </ngb-accordion>
                </div>

                <div
                  class="col-md-12 mb-3"
                  *ngIf="
                    this.shiftItem.confirmedStaffs.length > 0 &&
                    this.shiftItem.isExtendedShift == false &&
                    this.shiftItem.status != 2
                  "
                >
                  <ngb-accordion #a="ngbAccordion">
                    <ngb-panel id="custom-panel-1">
                      <ng-template ngbPanelHeader>
                        <button
                          ngbPanelToggle
                          type="button"
                          class="btn btn-block bg-theme"
                          data-toggle="collapse"
                          data-target="#adjustShift"
                          *ngIf="hideAllButton == true"
                        >
                          {{
                            !this.shiftItem.currentReqShift
                              ? " Request Shift Change"
                              : " Stop Request Shift Change"
                          }}

                          <i class="material-icons text-white down">
                            expand_more
                          </i>
                        </button>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        <div class="col-md-12 p-0" id="adjustShift" *ngIf="hideAllButton == true">
                          <div class="col-md-12 mb-3 no-padding flexDisplay">
                            <div class="col-6">
                              <div class="pr-2">Confirmed staff</div>
                              <input
                                class="form-control pr-2"
                                formControlName="backUpStaffNeedCountBooked"
                                [readonly]="!shiftItem.currentReqShift"
                              />
                            </div>
                            <div class="col-6">
                              <div class="pl-2">Required Count</div>
                              <input type="number"
                                class="form-control pl-2"
                                formControlName="reqStaffNeedCount"
                                (ngModelChange)="requiredCountChange($event, !shiftItem.currentReqShift)"
                              />
                              <div class="error-msg" *ngIf="isErrorDisplay">Required count cannot be more than Confirmed count</div>
                            </div>
                          </div>
                          <div class="col-md-12 mb-3 no-padding flexDisplay">
                            <div class="col-6">
                              <div>Original Timing</div>
                              <input
                                class="form-control pr-2"
                                formControlName="startTime"
                                readonly
                              />
                            </div>
                            <div class="col-6">
                              <div>&nbsp;</div>
                              <input
                                class="form-control pl-2"
                                formControlName="endTime"
                                readonly
                              />
                            </div>
                          </div>
                          <div class="col-md-12 mb-3 no-padding flexDisplay">
                            <div class="col-6">
                              <div class="pr-2">New Timing</div>
                              <ng-select
                                class="custom"
                                formControlName="reqStartTime"
                                appendTo="body"
                                [multiple]="false"
                                [searchable]="true"
                                [clearable]="true"
                                [closeOnSelect]="true"
                              >
                                <ng-option
                                  class="form-control"
                                  [value]="item"
                                  *ngFor="let item of timeDropDown"
                                >
                                  {{ item }}
                                </ng-option>
                              </ng-select>
                            </div>
                            <div class="col-6">
                              <div class="pl-2 mt-3"></div>
                              <ng-select
                                class="custom"
                                bindLabel="name"
                                formControlName="reqEndTime"
                                appendTo="body"
                                [multiple]="false"
                                [searchable]="true"
                                [clearable]="true"
                                [closeOnSelect]="true"
                              >
                                <ng-option
                                  class="form-control"
                                  [value]="item"
                                  *ngFor="let item of timeDropDown"
                                >
                                  {{ item }}
                                </ng-option>
                              </ng-select>
                            </div>
                          </div>
                          <div class="col-md-12 mb-3 no-padding flexDisplay">
                            <div class="col-6">
                              <div class="pr-2">Report To</div>
                              <input
                                class="form-control pr-2"
                                formControlName="reportLocationId"
                                readonly
                              />
                            </div>
                            <div class="col-6">
                              <div class="pl-2">New Report To</div>
                              <ng-select
                                class="custom"
                                bindLabel="name"
                                placeholder="Select Reporting Locations"
                                appendTo="body"
                                [multiple]="false"
                                [searchable]="true"
                                [clearable]="true"
                                formControlName="reqReportLocationId"
                              >
                                <ng-option
                                  [value]="item"
                                  *ngFor="let item of buRepoLocation"
                                  >{{ item.name }}
                                </ng-option>
                              </ng-select>
                            </div>
                          </div>
                          <div
                            *ngIf="
                              !this.shiftItem.currentReqShift;
                              then requestBtn;
                              else stopBtn
                            "
                          ></div>
                          <ng-template #requestBtn
                            ><button
                              class="btn btn-block btn-sm btn-success"
                              (click)="requestShiftChange(this.shiftItem)"
                            >
                              Request For Change
                            </button></ng-template
                          >
                          <ng-template #stopBtn>
                            <div class="col-md-12">
                              <button
                                class="btn btn-block btn-sm btn-danger"
                                (click)="stopRequestShiftChange(this.shiftItem)"
                              >
                                Stop Request For Change
                              </button>
                            </div>
                          </ng-template>
                        </div>
                      </ng-template>
                    </ngb-panel>
                  </ngb-accordion>
                </div>

                <div
                  class="col-md-12 my-3"
                  *ngIf="
                    this.shiftItem.confirmedStaffs.length > 0 &&
                    this.shiftItem.isExtendedShift == false &&
                    this.shiftItem.status != 2
                  "
                >
                  <ul
                    class="list-group"
                    *ngIf="this.shiftItem.requestedUsers.length > 0"
                  >
                    <li class="list-group-item list-group-item-dark">
                      <p class="font-weight-bold m-0 mtext-16">
                        Requested User List
                      </p>
                    </li>

                    <li
                      class="list-group-item d-flex justify-content-between align-items-center mtext-14"
                      *ngFor="let k of this.shiftItem.requestedUsers"
                    >
                      {{ k.userId.name }}
                      <div [ngSwitch]="k.status">
                        <span
                          class="badge badge-warning badge-pill"
                          *ngSwitchCase="0"
                          >Pending</span
                        >
                        <span
                          class="badge badge-success badge-pill"
                          *ngSwitchCase="1"
                          >Accepted</span
                        >
                        <span
                          class="badge badge-danger badge-pill"
                          *ngSwitchCase="2"
                          >Rejected</span
                        >
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="col-md-12 row no-gutters" *ngIf="cancelShiftbtn">
                  <button
                    type="button"
                    class="bg-theme btn btn-block mb-3 text-white"
                    (click)="cancelSingleShift(i)"
                  >
                    Cancel Shift
                  </button>
                </div>
              </div>
              <div class="col-md-12" *ngIf="!isShiftReqChange">
                <div
                  class="redButton col-md-12 mb-2"
                  *ngIf="!shiftItem.isAssignShift && !shiftItem.activeStatus && shiftItem.confirmedStaffs.length > 0"
                >
                  <b>Confirmed Staff List</b>
                </div>

                <!--<div
                  class="d-flex align-items-center userList justify-content-between mb-1 p-2"
                  *ngFor="let j of this.shiftItem.backUpStaffs"
                >
                  <h6 class="m-0">{{ j.name }}</h6>
                  <div class="circle ml-1"></div>
                </div>-->

                <div class="d-flex align-items-center">
                  <div class="col-md-12 p-0">
                    <ngb-accordion #a="ngbAccordion" class="accordion-new">
                      <ngb-panel
                        *ngFor="
                          let i of this.shiftItem.confirmedStaffs;
                          let j = index
                        "
                        formArrayName="extArr"
                        id="custom-panel-{{ j }}"
                      >
                        <ng-template ngbPanelHeader>
                          <div
                            class="d-flex justify-content-between userList justify-content-between mb-1 p-2"
                            *ngIf="!shiftItem.activeStatus">
                            <b *ngIf="!shiftItem.activeStatus && !shiftItem.isAssignShift" class="w-75">{{
                              i.name
                            }}<span style="margin-left: 3px;">({{i.staffId}}</span>)</b>

                            <div
                              class="d-flex align-items-center justify-content-center w-100"
                            >
                            <div *ngIf="hideBtn" class="d-flex align-items-center justify-content-center w-100">
                              <div style="display: block; margin-top: 10px;"   [ngClass]="{ 'w-100': shiftItem.isAssignShift}">
                                <button
                                *ngIf="!shiftItem.activeStatus && cancelBookedShiftButton == true && !shiftItem.isAssignShift"
                                type="button"
                                class="bg-theme btn btn-block mb-3 text-white"
                                (click)="cancelBookedShift(i)"
                              >
                                Cancel Booked Shift
                              </button>
                              <button
                              *ngIf="hideAllButton == true"
                              ngbPanelToggle
                              type="button"
                              class="btn extensionBtn"
                              data-toggle="collapse"
                              (click)="toggleshift(i)"
                              data-target="#adjustShift"
                              [ngClass]="{
                                'bg-theme': this.extShift[j][0] == 0 || this.extShift[j][0] == 4,
                                'btn-warning': this.extShift[j][0] == 1,
                                'btn-success': this.extShift[j][0] == 2,
                                'btn-danger': this.extShift[j][0] == 3
                              }"
                            >
                              <span [ngSwitch]="this.extShift[j][0]">
                                <span *ngSwitchCase="0">
                                   Request Shift Extension
                                </span>
                                <span *ngSwitchCase="1">
                                  Shift Extension Request Pending</span
                                >
                                <span *ngSwitchCase="2">
                                  Shift Extension Request Accepted</span
                                >
                                <span *ngSwitchCase="3">
                                  Shift Extension Request Rejected</span
                                >
                                <span *ngSwitchCase="4">
                                  Request Shift Extension
                               </span>
                              </span>

                              <i class="material-icons extdown">
                                expand_more
                              </i>
                            </button>
                             </div>
                            </div>

                              <div
                                class="circleConfirm ml-1"
                                *ngIf="!shiftItem.isAssignShift"
                              ></div>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent class="bg-light">
                          <div
                            class="col-md-12"
                            id="adjustShift"
                            [formGroupName]="j"
                          >
                            <div class="col-md-12 mb-3 no-padding">
                              <label for="">Time</label>
                              <div class="row mb-4">
                                <div class="col">
                                  <input
                                    type="text"
                                    class="form-control not-allowed"
                                    [value]="
                                      this.ShiftForm.controls.startTime.value
                                    "
                                    readonly
                                    disabled
                                  />
                                </div>
                                <div class="col">
                                  <input
                                    type="text"
                                    class="form-control not-allowed"
                                    [value]="
                                      this.ShiftForm.controls.endTime.value
                                    "
                                    readonly
                                    disabled
                                  />
                                </div>
                              </div>

                              <label for="">New Timing</label>
                              <div class="row mb-3">
                                <div class="col">
                                  <label for="">Start Date</label>
                                  <input
                                    class="form-control"
                                    placeholder="{{ i.startDate }}"
                                    ngbDatepicker
                                    formControlName="sd"
                                    [minDate]="minDate"
                                    [maxDate]="maxDate"
                                    #d1="ngbDatepicker"
                                    (click)="d1.toggle()"
                                    placement="top-right"
                                  />
                                </div>
                                <div class="col">
                                  <label for="">Start Time</label>
                                  <ng-select
                                    bindLabel="name"
                                    formControlName="ts"
                                    appendTo="body"
                                    [multiple]="false"
                                    [searchable]="true"
                                    [clearable]="true"
                                    [closeOnSelect]="true"
                                  >
                                    <ng-option
                                      class="form-control"
                                      [value]="item"
                                      *ngFor="let item of timeDropDown"
                                    >
                                      {{ item }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div class="col">
                                  <label for="">End Date</label>
                                  <input
                                    class="form-control"
                                    placeholder="{{ i.endDate }}"
                                    formControlName="ed"
                                    ngbDatepicker
                                    [minDate]="minDate"
                                    [maxDate]="maxDate"
                                    #d2="ngbDatepicker"
                                    (click)="d2.toggle()"
                                    placement="top-right"
                                  />
                                </div>
                                <div class="col">
                                  <label for="">End Time</label>
                                  <ng-select
                                    class="custom"
                                    bindLabel="name"
                                    formControlName="te"
                                    appendTo="body"
                                    [multiple]="false"
                                    [searchable]="true"
                                    [clearable]="true"
                                    [closeOnSelect]="true"
                                  >
                                    <ng-option
                                      class="form-control"
                                      [value]="item"
                                      *ngFor="let item of timeDropDown"
                                    >
                                      {{ item }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                              </div>

                              <button
                                class="btn btn-success btn-block"
                                (click)="saveConfirmed(i, j)"
                                *ngIf="this.extShift[j][0] == 0"
                              >
                                Send Shift Extension Request
                              </button>
                              <button
                                class="btn btn-danger btn-block"
                                (click)="stopShiftExtension(i)"
                                *ngIf="this.extShift[j][0] == 1"
                              >
                                Stop Shift Extension Request
                              </button>

                              <button
                                class="btn btn-success btn-block"
                                (click)="againShiftExt(i, j)"
                                *ngIf="
                                  this.extShift[j][0] == 2 ||
                                  this.extShift[j][0] == 3 ||
                                  this.extShift[j][0] == 4"
                              >
                                Send Shift Extension Request Again
                              </button>
                            </div>
                          </div>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                  </div>
                </div>
                <div
                class="redButton col-md-12 mb-2"
                *ngIf="!shiftItem.isAssignShift && shiftItem?.backUpStaffs.length > 0"
              >
                <b>Standby Staff List</b>
              </div>

              <div
                class="d-flex align-items-center userList justify-content-between mb-1 p-2"
                *ngFor="let j of this.shiftItem.backUpStaffs"
              >
                <h6>{{ j.name }}</h6>
                <div class="circle ml-1"></div>
              </div>
              </div>

              <div
                [ngClass]="cancel ? 'show-modal' : ''"
                class="modal"
                id="mmmModal"
              >
                <div class="modal-dialog" style="width: 50%; height: 200px">
                  <div class="modal-content modalContentBoxGrey">
                    <div class="modal-header"><b>Cancel Confirmation</b></div>
                    <div class="modal-body">
                      <div>
                        <h4 style="font-size: 17px">
                          Are you sure, you want to cancel this shift?
                        </h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        class="btn footer-button btn-danger"
                        style="font-size: 11px"
                      >
                        Yes
                      </button>
                      <button
                        class="btn footer-button btn-primary"
                        type="button"
                        (click)="cancelShift(false)"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div *ngIf="cfr">
              <form [formGroup]="restOffForm">
                <div class="form-group">
                  <label class="font-weight-bold">Business Unit</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="bu"
                    readonly
                  />
                </div>
                <div class="form-group">
                  <label class="font-weight-bold">Date</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="date"
                    readonly
                  />
                </div>
                <div class="form-group">
                  <label class="font-weight-bold">Staff Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="staffN"
                  />
                </div>
                <div class="form-group">
                  <label class="font-weight-bold">Staff ID</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="staffId"
                  />
                </div>

                <div class="row" *ngIf="this.selectedRecall.isRecalled == true">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="font-weight-bold">Time</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="startTime"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="font-weight-bold">&nbsp;</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="endTime"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="form-group"
                  *ngIf="this.selectedRecall.isRecalled == true"
                >
                  <label class="font-weight-bold">Report To</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="reportLoc"
                  />
                </div>
              </form>

              <div class="card">
                <div class="card-header redButton">
                  <h6 class="m-0">Recall</h6>
                </div>
                <div class="card-body">
                  <div
                    *ngIf="
                      this.selectedRecall.isRecalled == false &&
                      this.selectedRecall.isRecallAccepted == 1
                    "
                  >
                    <form [formGroup]="recallForm">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="font-weight-bold">Set Timing</label>
                            <ng-select
                              class="custom"
                              bindLabel="name"
                              appendTo="body"
                              placeholder="Select StartTime"
                              [multiple]="false"
                              [searchable]="true"
                              [clearable]="true"
                              [closeOnSelect]="true"
                              formControlName="StartTime"
                            >
                              <ng-option
                                class="form-control"
                                [value]="item"
                                *ngFor="let item of timeDropDown"
                              >
                                {{ item }}
                              </ng-option>
                            </ng-select>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="font-weight-bold">&nbsp;</label>
                            <ng-select
                              class="custom"
                              bindLabel="name"
                              appendTo="body"
                              placeholder="Select EndTime"
                              [multiple]="false"
                              [searchable]="true"
                              [clearable]="true"
                              [closeOnSelect]="true"
                              formControlName="EndTime"
                            >
                              <ng-option
                                class="form-control"
                                [value]="item"
                                *ngFor="let item of timeDropDown"
                              >
                                {{ item }}
                              </ng-option>
                            </ng-select>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-check form-check-inline float-right">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="splitTiming"
                              value="1"
                              (click)="onChangeSplit(!isSplit)"
                              name="splitTiming"
                            />
                            <label
                              class="form-check-label font-weight-bold"
                              for="inlineCheckbox1"
                              >Split Shift</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="row" *ngIf="isSplit">
                        <div class="col-md-4">
                          <div class="form-group">
                            <ng-select
                              class="custom"
                              bindLabel="name"
                              appendTo="body"
                              placeholder="Select StartTime"
                              [multiple]="false"
                              [searchable]="true"
                              [clearable]="true"
                              [closeOnSelect]="true"
                            >
                              <ng-option
                                class="form-control"
                                [value]="item"
                                *ngFor="let item of timeDropDown"
                              >
                                {{ item }}
                              </ng-option>
                            </ng-select>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <ng-select
                              class="custom"
                              bindLabel="name"
                              appendTo="body"
                              placeholder="Select EndTime"
                              [multiple]="false"
                              [searchable]="true"
                              [clearable]="true"
                              [closeOnSelect]="true"
                            >
                              <ng-option
                                class="form-control"
                                [value]="item"
                                *ngFor="let item of timeDropDown"
                              >
                                {{ item }}
                              </ng-option>
                            </ng-select>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="font-weight-bold">Reports to</label>
                            <ng-select
                              [items]="reportLocation"
                              class="custom"
                              bindLabel="name"
                              bindValue="_id"
                              placeholder="Select Location"
                              appendTo="body"
                              [multiple]="false"
                              [searchable]="true"
                              [clearable]="true"
                              [closeOnSelect]="true"
                              formControlName="reportLocation"
                            >
                            </ng-select>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="font-weight-bold"
                              >Select Skillsets
                            </label>
                            <ng-select
                              [items]="skillSet"
                              class="custom"
                              bindLabel="skillSetId.name"
                              placeholder="Skillsets"
                              appendTo="body"
                              [multiple]="false"
                              [searchable]="true"
                              [clearable]="true"
                              [closeOnSelect]="true"
                              formControlName="SkillSets"
                              (change)="
                                setSubSkill(
                                  this.recallForm.controls.SkillSets.value
                                )
                              "
                            >
                            </ng-select>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="font-weight-bold">
                              Select Speciality
                            </label>
                            <ng-select
                              [items]="subSkill"
                              class="custom"
                              bindLabel="name"
                              placeholder="Speciality"
                              appendTo="body"
                              [multiple]="false"
                              [searchable]="true"
                              [closeOnSelect]="true"
                              [clearable]="true"
                              formControlName="subSkillSets"
                            >
                            </ng-select>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <button class="btn btn-clear p-2">Clear</button>
                          <button class="btn btn-add p-2" (click)="addSkills()">
                            Add
                          </button>

                          <div *ngIf="this.skillsLabel != null">
                            <div
                              class="labels my-1"
                              *ngFor="let item of skillsLabel; let i = index"
                            >
                              <span class="text"
                                >{{ item[0] }} > {{ item[1] }}</span
                              >
                              <span
                                class="material-icons bg-danger rounded btn-close"
                                (click)="clearLabel(i)"
                              >
                                clear
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                    <button
                      type="submit"
                      class="btn btn-success"
                      (click)="shiftRecallSubmit()"
                      *ngIf="this.selectedRecall.isRecalled == false"
                    >
                      Submit
                    </button>
                  </div>

                  <div>
                    <h5
                      *ngIf="
                        this.selectedRecall.isRecallAccepted == 1 &&
                        this.selectedRecall.isRecalled == true
                      "
                    >
                      <span class="badge badge-warning p-2"
                        >Shift Recall Request Pending</span
                      >
                    </h5>
                    <h5 *ngIf="this.selectedRecall.isRecallAccepted == 2">
                      <span class="badge badge-success p-2"
                        >Shift Recall Request Accepted</span
                      >
                    </h5>
                    <h5 *ngIf="this.selectedRecall.isRecallAccepted == 3">
                      <span class="badge badge-danger p-2"
                        >Shift Recall Request Rejected</span
                      >
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <div *ngIf="isLoader" class="loader">
    <div class="pageLoading">
        <div class="innerDiv">
            <i class="fa fa-spinner fa-spin" style="float: none;"></i> Please wait, Data is Refreshing ......
        </div>
    </div>
</div>
