import { HttpHeaders, HttpClient,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {  generateHttpQueryParams, queryParam } from 'src/app/core/utils/helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomFormService {
  private baseUrl: string;
  private formServiceBaseUrl: string;
  private serviceModuleBaseUrl: string;
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  private get Header(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'text/csv',
    });
  }
  constructor(
    private http: HttpClient,
  ) {
    this.baseUrl = environment.baseUrl;
    this.formServiceBaseUrl = environment.formServiceBaseUrl;
    this.serviceModuleBaseUrl = environment.serviceModuleBaseUrl
  }
  getAllForms(data): Observable<any> {
    return this.http.get(`${this.formServiceBaseUrl}/api/form/list?${generateHttpQueryParams(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  singleFormData(id: string): Observable<any> {
    return this.http.get(`${this.formServiceBaseUrl}/api/form/single/${id}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  getsubmittedFormData(id: string): Observable<any> {
    return this.http.get(`${this.formServiceBaseUrl}/api/form/response/${id}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  getSubmittedFormDataAdmin(id: string): Observable<any> {
    return this.http.get(`${this.formServiceBaseUrl}/api/form/admin/response/${id}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  getWorkFlowLogs(data,workFlowID): Observable<any> {
    return this.http.get(`${this.baseUrl}/customform/readManageFormLog/${workFlowID}?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }
  getAllMyForms(data): Observable<any> {
    return this.http.get(`${this.formServiceBaseUrl}/api/form/myForms?${generateHttpQueryParams(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }
  getAllFormLogs(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/customForm/readManageFormLog?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }
  getAllManageFormLogs(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/custom-form/readManageFormLog?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  updateForm(data, id: string): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/custom-form/${id}`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  createForm(data): Observable<any> {
    return this.http
      .post(`${this.formServiceBaseUrl}/api/form`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  exportForm(data): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('timeZone', '-330');
    return this.http
      .post(`${this.baseUrl}/customForm/exportFormCsv`, data, {
        headers: this.Headers,
        params: queryParams,
      })
      .pipe(map((res) => res as any));
  }
  exportWorkFlowPdf(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/customForm/exportCustomForm`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  downloadCustomFormPdf(url): Observable<any> {
    const httpOptions = {
      responseType: ('blob' as 'json')
    };
    return this.http
      .get(`${this.baseUrl}${url}`, {
        headers: this.Headers,
        observe: 'body', responseType: 'blob' as 'json'
      })
      .pipe(map((res) => res as any));
  }

  getOneForm(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/custom-form/${id}`)
    .pipe(map((res) => res as any))
  }
  getFormSettings(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/customform/getFormSettings/${id}`)
    .pipe(map((res) => res as any))
  }
  setFormSettings(data): Observable<any> {
    return this.http
    .post(`${this.baseUrl}/customform/setFormSettings`, data, {
      headers: this.Headers,
    })
    .pipe(map((res) => res as any));
  }
  updateWorkflowStatus(data): Observable<any> {
    return this.http
    .post(`${this.baseUrl}/customForm/updateWorkflowStatus`, data, {
      headers: this.Headers,
    })
    .pipe(map((res) => res as any));
  }
  getOneFormManage(data,payload): Observable<any> {
    return this.http.post(`${this.baseUrl}/customform/readManageforms/${data.customFormId}?${queryParam(data)}` ,payload)
    .pipe(map((res) => res as any))
  }

  submitAnswers(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/custom-form/readManageforms/` ,data)
    .pipe(map((res) => res as any))
  }

  markAsAttendnace(data): Observable<any> {
    return this.http.post(`${this.formServiceBaseUrl}/api/form/mark/attendance`, data)
    .pipe(map((res) => res as any))
  }

  getManageFormUser(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/customForm/getManageFormUsers` ,data)
    .pipe(map((res) => res as any))
  }
  getExportCSV(data): Observable<any> {
    return this.http.get(`${this.baseUrl}${data}`, {responseType: 'text'})
    .pipe(map((res) => res as any))
  }
  updateFormStatus(data): Observable<any> {
    return this.http.put(`${this.baseUrl}/customForm/updateForm`, data)
    .pipe(map((res) => res as any))
  }
  uploadFiles(data) {
    return this.http.post(`${this.baseUrl}/external/uploadFiles` ,data)
    .pipe(map((res) => res as any))
  }

  cancelSubmission(data) {
    return this.http.post(`${this.formServiceBaseUrl}/api/form/manage/cancel`, data)
    .pipe(map((res) => res as any))
  }

  exportManageForm(data){
    return this.http.post(`${this.baseUrl}/customForm/exportManageForms?timeZone=-330` ,data)
    .pipe(map((res) => res as any))
  }

  getServicesList(): Observable<any> {
    return this.http.get(`${this.serviceModuleBaseUrl}/api/serviceSetup/serviceNames?page=1&limit=10&sort={"_id":-1}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  getQuestionList(formId, moduleId, type='view'): Observable<any> {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.http.get(`${this.formServiceBaseUrl}/api/form/${formId}/question/${moduleId}?type=${type}&timezone=${timeZone}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  formSubmissionList(customFormId, data, currentPage): Observable<any> {
    return this.http.post(`${this.formServiceBaseUrl}/api/form/manage/list/${customFormId}?limit=15&page=${currentPage}`, data, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  getFormsIndividualLogs(customFormId, currentPage): Observable<any> {
    return this.http.post(`${this.formServiceBaseUrl}/api/form/manage/log/${customFormId}?limit=10&page=${currentPage}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  resubmitForm(data, id: string): Observable<any> {
    return this.http
      .post(`${this.formServiceBaseUrl}/api/form/manage/save/${id}`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getStats(data): Observable<any> {
    return this.http.get(`${this.formServiceBaseUrl}/api/form/manage/stats/${data.customFormId}?serviceId=${data.serviceId}&buId=${data.buId}&status=${data.status}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  serviceTimeDropDownAll(serviceSetupId, timezone): Observable<any> {
    return this.http.post(`${this.serviceModuleBaseUrl}/api/service/timingsAsPerWeeksConfig`, {serviceSetupId, timezone}, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  serviceTimeDropDown(serviceSetupId, timezone): Observable<any> {
    return this.http.post(`${this.serviceModuleBaseUrl}/api/service/timings`, {serviceSetupId, timezone}, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  getTransportBusinessUnit(): Observable<any> {
    return this.http.get(`${this.formServiceBaseUrl}/api/master/transportbu`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }


  submitForm(data) {
    return this.http.post(`${this.formServiceBaseUrl}/api/form/submit` ,data)
    .pipe(map((res) => res as any))
  }

  exportData(customFormId, data): Observable<any> {
    return this.http.post(`${this.formServiceBaseUrl}/api/form/manage/export/${customFormId}`, data, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  indentDraft(data) {
    return this.http.post(`${this.formServiceBaseUrl}/api/form/manage/indent/draft` ,data)
    .pipe(map((res) => res as any))
  }

  indentDraftMultiple(data) {
    return this.http.post(`${this.formServiceBaseUrl}/api/form/manage/indent/draft/multiple` ,data)
    .pipe(map((res) => res as any))
  }

  multipleBooking(data) {
    return this.http.post(`${this.formServiceBaseUrl}/api/form/manage/multiple/booking` ,data)
    .pipe(map((res) => res as any))
  }

  generateAutoPopulateData(data) {
    return this.http.post(`${this.formServiceBaseUrl}/api/form/manage/autopopulatedata` ,data)
    .pipe(map((res) => res as any))
  }

  searchUserByText(data) {
    return this.http.get(`${this.formServiceBaseUrl}/api/form/manage/user/bytext?search=${data}`)
    .pipe(map((res) => res as any))
  }

  getSubmittedForms(id) {
    return this.http.get(`${this.formServiceBaseUrl}/api/form/submitted/web/${id}`)
    .pipe(map((res) => res as any))
  }

  getIndentLogs(data, customFormId): Observable<any> {
    return this.http.get(`${this.formServiceBaseUrl}/api/form/manage/log/${customFormId}?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  downloadLogError(data): Observable<any> {
    return this.http.post(`${this.formServiceBaseUrl}/api/form/manage/log/fileDownload`, data, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  getAdminTransportMasterData(type, data): Observable<any> {
    return this.http.get(`${this.formServiceBaseUrl}/api/master/${type}?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  exportTransportMasterData(type){
    return this.http.get(`${this.formServiceBaseUrl}/api/master/${type}/export`)
    .pipe(map((res) => res as any))
  }

  updateTransportMasterData(data: any, id: string, type: string): Observable<any> {
    return this.http
      .post(`${this.formServiceBaseUrl}/api/master/${type}/${id}`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  createTransportMasterData(data: any, type: string): Observable<any> {
    return this.http
      .post(`${this.formServiceBaseUrl}/api/master/${type}`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  importCSVMasterData(data: any, type: string): Observable<any> {
    return this.http
      .post(`${this.formServiceBaseUrl}/api/master/${type}/import`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
}
